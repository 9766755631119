//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import moment from "moment";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  newPassword: string;
  confirmPassword: string;
  isNewPasswordValid: boolean;
  isConfirmPasswordValid: boolean;
  newPasswordErrorMessage: string;
  confirmPasswordErrorMessage: string;
  file: any;
  userData: any;
  loading: boolean;
  openDialog: boolean;
  pushNotification: any;
  enable_load_limit: any;
  enableFlowLimit: any;
  targetNotify: any;
  updateLocalStorageProfile: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings2Controller extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  resetPasswordApiCallId: any;
  uploadProfileImageApiCallId: any;
  getUserProfileApiCallId: any;
  deleteProfileImageApiCallId: any;
  updateNotificationApiCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      newPassword: "",
      confirmPassword: "",
      isNewPasswordValid: true,
      isConfirmPasswordValid: true,
      newPasswordErrorMessage: "",
      confirmPasswordErrorMessage: "",
      file: null,
      userData: null,
      loading: true,
      openDialog: false,
      pushNotification: false,
      enable_load_limit: false,
      enableFlowLimit: false,
      targetNotify: false,
      updateLocalStorageProfile: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId == this.resetPasswordApiCallId) {
        if (responseJson.data) {
          toast.success("Password updated successfully.");
        } else if (responseJson.errors) {
          if (responseJson.errors.length !== 0) {
            if (responseJson.errors[0].token) {
              //this.parseApiCatchErrorResponse(responseJson.errors[0].token);
              toast.error(responseJson.errors[0].token);
            } else if (responseJson.errors[0].profile) {
              // this.parseApiCatchErrorResponse(responseJson.errors[0].password);
              toast.error(responseJson.errors[0].profile);
            }
          }
        }
      } else if (apiRequestCallId === this.uploadProfileImageApiCallId) {
        if (responseJson.data && responseJson.meta) {
          if (Object.keys(responseJson.data).length !== 0) {
            if (responseJson.meta.message) {
              this.setState({ updateLocalStorageProfile: true });
              toast.success(responseJson.meta.message);
              this.getUserProfile();
            }
          }
        } else {
          this.setState({ loading: false });
          if (responseJson.message) {
            this.showErrorToast(responseJson);
          } else {
            toast.error("Something went wrong.");
          }
        }
      } else if (apiRequestCallId === this.getUserProfileApiCallId) {
        if (responseJson.data) {
          if (Object.keys(responseJson.data).length !== 0) {
            const usersData = responseJson.data;

            if (this.state.updateLocalStorageProfile) {
              this.updateUsersLocalObj(responseJson.data);
            }
            if (usersData?.attributes?.activation_date) {
              const activatedDate = new Date(usersData.attributes.activation_date);
              usersData.attributes.activation_date = moment(activatedDate).format("DD-MM-YYYY");
            }
            if (usersData?.attributes?.renewal_date) {
              const activatedDate = new Date(usersData.attributes.renewal_date);
              usersData.attributes.renewal_date = moment(activatedDate).format("DD-MM-YYYY");
            }
            this.setState({
              userData: usersData,
              pushNotification:
                responseJson.data.attributes.enable_notification,
              enable_load_limit: responseJson.data.attributes.enable_load_limit,
              enableFlowLimit: responseJson.data.attributes.enable_flow_limit,
              targetNotify:
                responseJson.data.attributes.enable_target_complete,
              loading: false,
            });
          } else {
            this.setState({ loading: false });
          }
        }
      } else if (apiRequestCallId === this.deleteProfileImageApiCallId) {
        if (responseJson.data) {
          this.setState({ updateLocalStorageProfile: true });
          toast.success("Image deleted successfully");
          this.handleCloseDialog();
          this.getUserProfile();
        } else {
          if (responseJson.message) {
            this.showErrorToast(responseJson);
          } else {
            toast.error("Something went wrong.");
          }
          this.handleCloseDialog();
        }
      } else if (apiRequestCallId === this.updateNotificationApiCallId) {
        console.log("updated", responseJson);
        if (responseJson) {
          if (responseJson.message) {
            this.showErrorToast(responseJson);
          } else {
            if (Object.keys(responseJson).length !== 0) {
              this.setState({
                pushNotification: responseJson.enable_notification,
                enableFlowLimit: responseJson.enable_flow_limit,
                enable_load_limit: responseJson.enable_load_limit,
                targetNotify: responseJson.enable_target_complete,
                loading: false,
              });
            }
            toast.success("Changes updated successfully");
          }
        } else {
          toast.error("Something went wrong.");
        }
        // this.getUserProfile();
      }
    }
    // Customizable Area End
  }

  updateUsersLocalObj = (newUserInfo: any) => {
    // this.setState({})
    let userInformation: any = localStorage.getItem('userInfo');
    userInformation = JSON.parse(userInformation);
    userInformation.data.attributes.photo = newUserInfo?.attributes?.photo;
    localStorage.setItem("userInfo", JSON.stringify(userInformation));
    window.location.reload();
  }

  showErrorToast = (resObj: any) => {
    if (resObj.message) {
      resObj.status !== 200 ? toast.error(resObj.message) : toast.success(resObj.message);
    }
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({
        enableField: !this.state.enableField,
      });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // Customizable Area Start

  async componentDidMount() {
    this.getUserProfile();
  }

  getUserProfile = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.getUserProfileApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUserProfileApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleNewPasswordChange = (event: any) => {
    if (event.target.value !== "") {
      if (event.target.value === this.state.confirmPassword) {
        this.setState({
          newPassword: event.target.value,
          isNewPasswordValid: true,
          newPasswordErrorMessage: "",
          isConfirmPasswordValid: true,
          confirmPasswordErrorMessage: "",
        });
      } else {
        this.setState({
          newPassword: event.target.value,
          isConfirmPasswordValid: false,
          confirmPasswordErrorMessage: "Password Does not Match",
        });
      }
    } else {
      this.setState({
        newPassword: "",
        isNewPasswordValid: false,
        newPasswordErrorMessage: "Field Cannot be blank.",
      });
    }
  };
  handleConfirmPasswordChange = (event: any) => {
    if (event.target.value !== "") {
      if (this.state.newPassword == event.target.value) {
        this.setState({
          confirmPassword: event.target.value,
          isConfirmPasswordValid: true,
          confirmPasswordErrorMessage: "",
        });
      } else {
        this.setState({
          confirmPassword: event.target.value,
          isConfirmPasswordValid: false,
          confirmPasswordErrorMessage: "Password Does not Match",
        });
      }
    } else {
      this.setState({
        confirmPassword: "",
        isConfirmPasswordValid: false,
        confirmPasswordErrorMessage: "Field cannot be blank.",
      });
    }
  };

  handleResetPasswordClick = () => {
    if (
      this.state.newPassword !== "" &&
      this.state.confirmPassword !== "" &&
      this.state.isNewPasswordValid &&
      this.state.isConfirmPasswordValid
    ) {
      this.resetPassword();
    } else {
      if (this.state.newPassword !== this.state.confirmPassword) {
        this.setState({
          isConfirmPasswordValid: false,
          confirmPasswordErrorMessage: "Password does not match",
        });
      }
      if (this.state.newPassword == "") {
        this.setState({
          isNewPasswordValid: false,
          newPasswordErrorMessage: "Field cannot be blank.",
        });
      }
      if (this.state.confirmPassword == "") {
        this.setState({
          isConfirmPasswordValid: false,
          confirmPasswordErrorMessage: "Field cannot be blank.",
        });
      }
    }
  };

  resetPassword = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.resetPasswordApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resetPasswordApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const httpBody = {
      data: {
        new_password: this.state.newPassword,
      },
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiPutType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleFileChange = (acceptedFiles: any) => {
    // console.log(acceptedFiles[0], "acceptedFiles[0].size", acceptedFiles[0].size / 1024 / 1024)
    if ((acceptedFiles[0].size / 1024 / 1024) <= 1) {
      this.setState(
        {
          file: acceptedFiles,
        },
        () => {
          this.setState({ loading: true });
          this.uploadProfileImage(acceptedFiles);
        }
      );
    } else {
      toast.error('File size cannot exceed 1mb');
    }
  };

  uploadProfileImage = (file: any) => {
    const header = {
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.uploadProfileImageApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.uploadPhotoApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    var bodyFormData = new FormData();
    bodyFormData.append("image", file[0]);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiPutType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      bodyFormData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  deleteProfileImage = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.deleteProfileImageApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.removePhotoApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiPutType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleCloseDialog = () => {
    this.setState({
      openDialog: !this.state.openDialog,
    });
  };

  handleNotificationCheckbox = (event: any) => {
    let params = `?${event.target.name}=${event.target.checked}`;
    this.updateNotificationSettings(params);
  };

  updateNotificationSettings = (type: any) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.updateNotificationApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateNotificationApiEndPoint}${type}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiPutType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  // Customizable Area End
}
