//@ts-nocheck
import React from 'react'
import { Grid, Container, Card, CardContent, FormControl, Typography, InputLabel, Select, MenuItem, Box } from "@material-ui/core";
import { withStyles } from "@material-ui/styles"
import DashboardController, { Props } from "./DashboardController";
import DeviceStats from './DeviceStats.web';
// import DeviceLogs from './DeviceLogs.web';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, BarElement, BarController } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { getFormatedDate, pageLoader } from './utility';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


ChartJS.register(ArcElement, Tooltip, Legend, BarElement, BarController);

const backgroundImage = require("../assets/d556836cdbf3f98427e2ff9a6d3a66e43cc7b98f.png")

export class DashboardInner extends DashboardController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    this.getLogDeviceList();
    this.getDeviceDetailsLocationWise();
    //Customizable Area End
  }

  render() {
    const { classes } = this.props;
    const { selectedDevice, updatedDevicesObj, locationWiseDevices } = this.state;

    let labels: any = [];
    let data: any = [];
    let color: any = [];

    locationWiseDevices.map((locDevice: any) => {
      const deviceArr: any = Object.values(locDevice)[0];
      deviceArr.length > 0 && deviceArr.map((dev: any) => {
        if(dev.sub_device.sid_name?.length >= 15){
          const subDevideName = dev.sub_device.sid_name || "";
          const newSubDeviceName = `${subDevideName.substr(0,6)}...${subDevideName.substr((subDevideName.length)-5,subDevideName.length)}`;
          labels.push(newSubDeviceName);
        }else{
          labels.push(dev.sub_device.sid_name);
        }

        if(dev.mqttData?.modbus.length > 1) {
          dev.mqttData?.modbus.map((multiDevices:any) => {
            if(multiDevices.sid == dev.sub_device.sid){
              data.push(multiDevices?.Total||0.00);
            }
          })
        }else if (dev.mqttData?.modbus[0]?.Total) {
          data.push(dev.mqttData.modbus[0]?.Total);
        } else {
          data.push(0.00);
        }
        color.push(dev.sub_device.sid_color);
      })
    })

    const chartData = {
      labels: labels,
      datasets: [
        {
          labels: labels,
          data: data,
          backgroundColor: color,
          borderColor: color,
          width: 1,
          maxBarThickness: 25
        },
      ],
      
    };
    return (
      <>
        <ToastContainer />       
        <div className={classes.main}>
          <Container maxWidth="xl">
            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={9} className={classes.centerContent}>
                    <Grid container>
                      {
                        this.state.locationWiseDevicesLoad ? pageLoader() :
                          locationWiseDevices.length > 0 ? locationWiseDevices.map((device: any, index: number) => <DeviceStats
                            key={`DashboardModem${index}`}
                            navigation={undefined}
                            modemDetails={device}
                          />) : <Typography gutterBottom variant="h5" component="div">
                            No linked devices found!
                          </Typography>
                      }

                    </Grid>
                  </Grid>
                  <Grid item xs={3} className={classes.sidePanel}>
                    <Card className={classes.card2}>
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          Production in tons
                        </Typography>
                        <Grid container >
                          <Grid item xs={12} className={""}>
                          <div style={{width: "100%", overflowX: "auto", overflowY: "hidden"}}>
                            <div style={{width: `${labels.length > 8 ? labels.length * 35 : 300}px`}}>
                            <Bar
                              data={chartData}
                              options={{
                                plugins: { legend: { display: false } },
                              }}
                            />
                            </div>
                          </div>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                    <Card className={classes.card}>
                      <CardContent>
                        <Grid container style={{ marginBottom: '1rem' }}>
                          <Grid item xs={4}>
                            <Typography gutterBottom variant="h5" component="div" style={{ paddingTop: '1rem' }}>
                              Logs
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">Device</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="deviceLogsChange"
                                value={this.state.logs.id}
                                label="Device"
                                onChange={this.handleSelectedDeviceChange}
                              >
                                {this.state.subDevicesList.map((device: any) => <MenuItem key={device.id} value={device.id}>{device.sid_name}</MenuItem>)}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                        {this.state.logs.loader ? pageLoader() :
                          <Grid container>
                            {this.state.logs.logsArray.map((logsDate:any,index:Number) => <Grid key={`logsDate${index}${logsDate.name}`} item xs={12}>
                              <Box
                                className={classes.logsDiv}
                              >
                                <Grid container>
                                  <Grid item xs={7}>
                                    <Typography className={classes.mediumText1}>
                                      {logsDate.name}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={5}>
                                    <Typography className={classes.logsDivTime}>
                                      {getFormatedDate(logsDate.date)?.date}
                                    </Typography>
                                    <Typography className={classes.logsDivTime}>
                                      {getFormatedDate(logsDate.date)?.time}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Grid>)}
                          </Grid>}
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </div>
      </>
    )
  }
}

// Customizable Area Start
const styles = () => ({
  sidePanel: {
    padding: '15px 10px',
  },
  main: {
    ['& .MuiContainer-root']: {
      padding: 0,
    },
    backgroundImage: `url(${backgroundImage})`,
    paddingTop: '10px',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  },
  centerContent: {
    padding: '0 0 0 10px',
    borderRight: '2px solid lightgrey'
  },
  card: {
    borderRadius: 20,
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    padding: "20px",
    marginBottom: '20px',
  },
  card2: {
    borderRadius: 20,
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",

    marginBottom: '20px',
  },
  padding25: {
    padding: "25px",
  },
  prodGraph: {
    // borderBottom: '2px solid lightgrey',
    padding: '0 10px'
  },
  logsDiv: {
    borderLeft: '5px solid red',
    paddingLeft: '10px',
    marginBottom: '20px',
  },
  logsDivTime: {
    color: '#79A1FA',
    fontSize: 14,
    fontWeight: 600
  },
  mediumText: {
    color: 'grey',
    // fontSize: '13px'
  },
  mediumText1: {
    fontWeight: 600
  },
  backdrop: {
    zIndex: 1500,
    color: '#fff',
  },
});
// Customizable Area End

export default withStyles(styles)(DashboardInner)
