//@ts-nocheck
import React from 'react'
import { Grid, Typography, Card, CardContent, Box, LinearProgress, Button, FormControl, Select, MenuItem } from "@material-ui/core";
import { withStyles } from "@material-ui/styles"
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Link } from 'react-router-dom';

// Customizable Area Start
import mqtt from 'mqtt';
import { pageLoader } from './utility';
// Customizable Area End

export interface Props {
  navigation: any;
  modemDetails: any;
  // id: string;
  // Customizable Area Start
  classes: any;
  deviceName?: any;
  deviceImeiNumber?: any;
  deviceSid?: any;
  deviceFlow?: any;
  deviceSpeed?: any;
  deviceLoad?: any;
  deviceTotal?: any;
  deviceStat?: any;
  deviceTargetFlow?: any;
  deviceTargetSpeed?: any;
  deviceTargetLoad?: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  runningStatus?: boolean;
  lastUpdatedOn?: any;
  deviceIndex?: any;
  timed?: boolean;
  // deviceDetails?: any;
  // Customizable Area End
}
interface SS {
  id: any;
}

const RunningIcon = require('../assets/running.png');
const StoppedIcon = require('../assets/stopped.png');

export class DeviceStats extends BlockComponent<Props, S, SS> {
  interval: any;

  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    this.state = {
      // flow: '0.00',
      // speed: '0.00',
      // load: '0.00',
      // total: '0.00 t',
      runningStatus: false,
      lastUpdatedOn: Math.round(+new Date() / 1000),
      deviceIndex: 0,
      timed:false
      // deviceDetails: {
      //   sid_name: "",
      //   classes: "",
      //   deviceTargetFlow: "",
      //   deviceTargetSpeed: "", deviceTargetLoad: "", sid: ""
      // }
    };
    //Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.interval = setInterval(() => this.tick(), 1000);
    if(!this.state.timed){
      setTimeout(() => this.setState({timed: true}),30000)
   }
    // this.getMqttData();
  }

  async componentWillUnmount() {
    clearInterval(this.interval);
  }

  tick() {
    const now = Math.round(+new Date() / 1000);
    const { lastUpdatedOn } = this.state;

    if (now - lastUpdatedOn >= 300) {
      console.log('5 miuntes of inactivity. Machine is STOPPED!');
      this.setState(state => ({     
        runningStatus: false,
      }));
    }
  }

  changeIndex(event: any, change: any) {
    if(event && change){
      event.preventDefault();
      (change >= 0) && this.setState({ ...this.state, deviceIndex: change })
    }
    
  }

  render() {

    const { modemDetails, classes } = this.props;
    let startedDevice = false;
    const locationName = Object.keys(modemDetails)[0];
    const devices: any = Object.values(modemDetails)[0];
    const deviceImeiNumber = devices[this.state?.deviceIndex].imei;
    const subDevice = devices.length > 0 ? devices[this.state?.deviceIndex] : {};
    // console.log(subDevice);
    const { sub_device } = subDevice;
    let deviceFlow, deviceSpeed, deviceLoad, deviceTotal, disconnected;

    if(subDevice?.mqttData && subDevice?.mqttData?.modbus?.length>1){
      // devices[this.state.deviceIndex].sub_device.sid
      subDevice?.mqttData?.modbus?.map((multiSubItem: any) => {
        if(multiSubItem.sid == devices[this.state.deviceIndex].sub_device.sid){
          deviceFlow = multiSubItem.Flow ?? '0.00';
          deviceSpeed = multiSubItem.Speed ?? '0.00';
          deviceLoad = multiSubItem.Load ?? '0.00';
          deviceTotal = multiSubItem.Total ?? '0.00';
          disconnected = multiSubItem.disconnected; 
          return true
        }
      })
    }else{
      deviceFlow = subDevice?.mqttData?.modbus[0]?.Flow ?? '0.00';
      deviceSpeed = subDevice?.mqttData?.modbus[0]?.Speed ?? '0.00';
      deviceLoad = subDevice?.mqttData?.modbus[0]?.Load ?? '0.00';
      deviceTotal = subDevice?.mqttData?.modbus[0]?.Total ?? '0.00';
      disconnected = subDevice?.mqttData?.modbus[0]?.disconnected;  
    }
 
    const { runningStatus } = this.state;
    if (deviceSpeed > 0) {
      startedDevice = true;
    }

    const handleSelectedDeviceChange = (event: any, val: any) => {
      event.preventDefault();
      const changedSid = val.props.value;
      const getIndex = devices.findIndex((e: any) => e.sub_device.sid == changedSid);
      getIndex >= 0 && this.setState({ ...this.state, deviceIndex: getIndex });
    }

    return (
      <Grid item xs={4} className={classes.cardParent}>
        <Link
          id="linkToDeviceInfo"
          to={`/Dashboard/DeviceInfo/${deviceImeiNumber}/${sub_device.sid}`}
          style={{
            textDecoration: "none",
            color: "black",
          }}
        >
          <Card className={classes.card} style={css.postionRel}>
            <CardContent style={{ padding: '15px 2px 2px 2px ' }}>
              {devices.length > 1 && <Typography className={classes.conveyesCount} style={css.postionAbs}>
                {devices.length} Conveyers
              </Typography>}
              {(this.state.deviceIndex !== devices.length - 1) && <Button
                id='deviceIndex'
                style={css.postionAbs}
                onClick={(event: any) => this.changeIndex(event, this.state.deviceIndex + 1)}
                className={classes.rightIcon}
                variant="text"
                id="nextIndexArrow"
              >
                &#8250;
              </Button>}
              {this.state.deviceIndex !== 0 && <Button
                style={css.postionAbs}
                onClick={(event) => this.changeIndex(event, this.state.deviceIndex - 1)}
                className={classes.leftIcon}
                variant="text"
                id="leftIndexArrow"
                
              >
                &#8249;
              </Button>}
              <Typography id="loactionName" className={classes.modemName} gutterBottom variant="h5" component="div">
                {locationName}
              </Typography>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Select
                    id="DashBoardModemSelectDevice"
                    value={devices[this.state.deviceIndex].sub_device.sid}
                    className={classes.cardDeviceDropdown}
                    disableUnderline={true}
                    onChange={handleSelectedDeviceChange}
                    inputProps={{
                      classes: {
                        icon: classes.selectIcon,
                      },
                    }}
                  >
                    {devices.map((device: any) => <MenuItem key={device.sub_device.sid} value={device.sub_device.sid}>{device.sub_device.sid_name}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>
              {
                subDevice.loading && !this.state.timed ? pageLoader() : <Grid item xs={12}>
                  <Typography className={disconnected ? classes.disconnected : classes.quantity}>
                    {disconnected ? 'Disconnected' : deviceTotal + ' t'}
                  </Typography>
                  <Grid container style={{ marginBottom: "15px" }}>
                    <Grid item xs={6}>
                      <Box
                        className={classes.leftBox}
                        style={css.flexDirectionCol}
                      >
                        <Typography className={classes.mediumText} style={css.fontBold}>
                          Flow
                        </Typography>
                        <Typography style={css.wordBreak} className={classes.speed}>
                          {deviceFlow}
                        </Typography>
                        <Typography className={classes.smallText} style={css.fontBold}>
                          TPH
                        </Typography>
                        <LinearProgress variant="determinate" color='primary' value={((deviceFlow / subDevice.target_flow) * 100||0)} />
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box
                        style={css.flexDirectionCol}
                        className={classes.rightBox}
                      >
                        <Typography className={classes.mediumText} style={css.fontBold}>
                          Speed
                        </Typography>
                        <Typography className={classes.speed} style={css.wordBreak}>
                          {deviceSpeed}
                        </Typography>
                        <Typography className={classes.smallText} style={css.fontBold}>
                          M/S
                        </Typography>
                        <LinearProgress variant="determinate" color='primary' value={(deviceSpeed / subDevice?.target_speed * 100||0)} />
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={9}>
                      <Box
                        mt={2}
                        sx={{
                          height: "100%",
                          border: '1px solid lightgrey',
                          borderRadius: 25,
                          padding: '15px 12px',
                          marginTop: "0"
                        }}
                      >
                        <Typography className={classes.mediumText}>
                          Load
                        </Typography>
                        <Typography className={classes.speed} style={css.wordBreak}>
                          {deviceLoad}
                        </Typography>
                        <Typography className={classes.smallText}>
                          KG/M
                        </Typography>
                        <LinearProgress variant="determinate" color='primary' value={(deviceLoad / subDevice?.target_load * 100||0)} />
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box
                        mt={2}
                        className={startedDevice ? classes.deviceStatusRunning : classes.deviceStatusStopped}
                      >
                        <img id="runningStoppedImg" src={startedDevice ? RunningIcon : StoppedIcon} width="100%" height="100%" className={classes.imgs} />
                      </Box>
                    </Grid>
                  </Grid>

                </Grid>
              }

            </CardContent>
          </Card>
        </Link>
      </Grid>
    )
  }
}

const positionCss = {
  relative: "relative",
  absolute: "absolute"
}

// Customizable Area Start
export const styles = () => ({
  card: {
    // margin: '20px 28px',
    borderRadius: 20,
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    padding: "20px",
    height: '100%',
  },
  cardParent: {
    padding: '15px 10px'
  },
  cardDeviceDropdown: {
    backgroundColor: '#79A1FA',
    color: 'white',
    padding: "0px 15px",
    borderRadius: 5,
    marginBottom: "10px"
  },
  rightIcon: {
    right: 0,
    top: "48%",
    padding: "9px",
    minWidth: "auto",
    fontSize: 35
  },
  leftIcon: {
    left: 0,
    top: "48%",
    padding: "9px",
    minWidth: "auto",
    fontSize: 35
  },
  conveyesCount: {
    padding: "5px 10px",
    top: 0,
    right: 0,
    borderTopRightRadius: 20,
    borderBottomLeftRadius: 20,
    backgroundColor: "#79a1fa52",
    color: '#79A1FA',
  },
  modemName: {
    marginBottom: "10px"
  },
  quantity: {
    color: '#79A1FA',
    fontSize: '40px',
    fontWeight: 700,
  },
  disconnected: {
    color: 'red',
    fontSize: '20px',
    marginBottom: "10px",
    fontWeight: 700,
  },
  speed: {
    fontSize: '16px',
    fontWeight: 600,
  },
  mediumText: {
    fontSize: '13px',
    color: 'grey',
  },
  smallText: {
    fontSize: '11px',
    color: 'grey',
  },
  leftBox: {
    border: '1px solid lightgrey',
    borderTopLeftRadius: 25,
    borderBottomLeftRadius: 25,
    padding: '15px 12px',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  rightBox: {
    border: '1px solid lightgrey',
    borderTopRightRadius: 25,
    borderBottomRightRadius: 25,
    borderLeft: 'none',
    padding: '15px 12px',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  deviceStatusStopped: {
    marginLeft: '25%',
    width: '70%',
    height: 120,
    marginTop: 0
  },
  deviceStatusRunning: {
    marginLeft: '25%',
    width: '70%',
    height: 120,
    marginTop: 0
  },
  selectIcon: {
    color: 'white'
  }
});

const css: { [key: string]: React.CSSProperties } = {
  postionRel: {
    position: "relative",
  },
  postionAbs: {
    position: "absolute",
  },
  fontBold: {
    fontWeight: "bold"
  },
  wordBreak: {
    overflowWrap: 'break-word',
  },
  flexDirectionCol: {
    flexDirection: 'column'
  }
};
// Customizable Area End



export default withStyles(styles, { withTheme: true })(DeviceStats)