//@ts-nocheck
import React, { Component } from "react";
import {
  Grid,
  Button,
  Typography,
  Card,
  Theme,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Box,
  Avatar,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Menu,
  MenuItem
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { Link } from "react-router-dom";


import AdHocReportingController, { Props } from "./AdHocReportingController";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getformatedCurrentDate, noDataFound, pageLoader } from "../../dashboard/src/utility";

const actionDots = require("../assets/3dots.png");
const exportIcon = require("../assets/exporticon.png");

export class AdHocReporting extends AdHocReportingController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    //Customizable Area End
  }

  async componentDidMount() {
    this.getReportsData('default');
    this.getDevicesList();
  }
  render() {
    const { navigation, classes } = this.props;

    return (
      <React.Fragment>
        <ToastContainer />
        <Grid container style={{ padding: "20px" }}>
          <Card className={classes.mainCard}>
            <Grid container spacing={2} style={{ padding: "20px" }}>
              <Grid item xs={12}>
                <Grid container spacing={2} style={{ paddingLeft: "10px" }} >
                  <Grid item xs={12}>
                    <Typography variant="body2" className={classes.text1}>
                      Production Summary Report
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container style={{ paddingRight: '10px' }}>
                      <Grid item className={classes.fieldContainer}>
                        <TextField
                          testID="startDate"
                          fullWidth
                          variant="filled"
                          label="Start date"
                          type="datetime-local"
                          name="start_date"
                          InputLabelProps={{
                            shrink: true
                          }}
                          InputProps={{
                            classes: { root: classes.inputWhite },
                            inputProps: { max: getformatedCurrentDate('time') }
                          }}
                          size="small"
                          value={this.state.filterObj.start_date}
                          onChange={this.filterFieldChange}
                          error={!this.state.isdateValid}
                          helperText={
                            <span style={{ fontSize: "16px", color: 'red' }}>
                              {this.state.errMsgOnDate}
                            </span>
                          }
                        />

                      </Grid>
                      <Grid item className={classes.fieldContainer}>
                        <FormControl fullWidth>
                          <TextField
                            testID="endDate"
                            fullWidth
                            variant="filled"
                            label=" End date"
                            type="datetime-local"
                            name="end_date"
                            InputLabelProps={{
                              shrink: true
                            }}
                            InputProps={{
                              classes: { root: classes.inputWhite },
                              inputProps: { max: getformatedCurrentDate('time') }
                            }}
                            value={this.state.filterObj.end_date}
                            onChange={this.filterFieldChange}
                            size="small"
                            error={!this.state.end_dateValid}
                            helperText={
                              <span style={{ fontSize: "16px", color: 'red' }}>
                                {this.state.errMsgOn_endDate}
                              </span>
                            }

                          />
                          {this.state.end_dateValid ? "" : <span className={classes.textRed}>Please enter a valid end date</span>}
                        </FormControl>
                      </Grid>
                      <Grid item className={classes.fieldContainer}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">Device</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={this.state.filterObj.deviceId}
                            label="Device"
                            onChange={this.filterFieldChange}
                            name="deviceId"
                            error={!this.state.isDeviceSelected}
                          >
                            <MenuItem key={"all"} value='all'>All</MenuItem>
                            {this.state.subDevicesList.map((device: any, index: any) => <MenuItem key={index} value={device.id}>{device.sid_name}</MenuItem>)}
                          </Select>
                          {this.state.isDeviceSelected ? "" : <span className={classes.textRed}>Please enter a device</span>}

                        </FormControl>
                      </Grid>

                      <Grid item style={{ marginTop: "auto" }}>
                        <Button
                          id="btnFilter"
                          variant="contained"
                          onClick={this.filterReports}
                          size="small"
                          style={{ marginRight: '5px' }}
                        >
                          Filter
                        </Button>
                        <Button
                          id='btnResetFilters'
                          variant="contained"
                          onClick={this.resetFilters}
                          size="small"
                        >
                          Reset
                        </Button>

                      </Grid>
                      <Grid item style={{ marginTop: "auto", marginLeft: 'auto' }}>

                        <Button
                          id="btnExportPDF"
                          variant="contained"
                          className={classes.exportBtn}
                          startIcon={<img src={exportIcon} />}
                          onClick={this.exportPDFData}
                          style={{ marginRight: "10px" }}
                          disabled={this.state.reportsData.length > 0 ? false : true}
                        >
                          <Typography variant="body2" className={classes.text3}>
                            Export PDF
                          </Typography>
                        </Button>

                        <Button
                          id="exportCSVBtn"
                          variant="contained"
                          className={classes.exportBtn}
                          startIcon={<img src={exportIcon} />}
                          onClick={this.exportCSVData}
                          disabled={this.state.reportsData.length > 0 ? false : true}
                        >
                          <Typography variant="body2" className={classes.text3}>
                            Export CSV
                          </Typography>
                        </Button>

                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

              </Grid>
              <Grid item xs={12}>
                {
                  this.state.pageLoader ? pageLoader() : this.state.reportsData.length > 0 ? <TableContainer>
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <Typography
                              id="tableHeadName"
                              variant="body2"
                              className={classes.tableHeads}
                            >
                              Name
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="body2"
                              className={classes.tableHeads}
                            >
                              Rate(ton/hr)
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="body2"
                              className={classes.tableHeads}
                            >
                              Production ( tons)
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="body2"
                              className={classes.tableHeads}
                            >
                              Action
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.reportsData.length > 0 && this.state.reportsData.map((eachReport: any, index:any) => eachReport && (
                            <TableRow key={index}>
                              <TableCell className={classes.tableDatas}>
                                {eachReport.name}
                              </TableCell>

                              <TableCell className={classes.tableDatas}>
                                {eachReport.average_rate}
                              </TableCell>
                              <TableCell className={classes.tableDatas}>
                                {eachReport.production}
                              </TableCell>
                              <TableCell>
                                <IconButton id="handleOpen" onClick={(event) => this.handleOpenAboutMenu(event, eachReport)}>
                                  <img src={actionDots} />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                        ))}
                        <Menu
                          id="menuClose"
                          anchorEl={this.state.anchorEl3}
                          keepMounted
                          open={Boolean(this.state.anchorEl3)}
                          style={{ marginTop: "40px" }}
                          onClose={this.handleAboutMenuClose}
                        >
                          <Link
                            to={`/Dashboard/DeviceInfo/${this.state.selectedReports?.imei}/${this.state.selectedReports?.sid}?scroll=bottom`}
                            style={{
                              textDecoration: "none",
                              color: "black",
                            }}
                          >
                            <MenuItem >View Details</MenuItem>
                          </Link>

                        </Menu>
                      </TableBody>
                    </Table>
                  </TableContainer> : noDataFound()}
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </React.Fragment>
    );
  }
}

// Customizable Area Start
const styles = (theme: Theme) => ({
  text1: {
    opacity: 1,
    fontWeight: 500,
    color: "rgba(17, 20, 45, 1)",
    fontFamily: "Poppins",
    fontSize: "18px",
  },
  text2: {
    opacity: 1,
    fontStyle: "normal",
    fontWeight: 500,
    color: "rgba(128, 129, 145, 1)",
    fontFamily: "Poppins",
    fontSize: "13px",
  },
  text3: {
    opacity: 1,
    fontWeight: 500,
    color: "rgba(255, 255, 255, 1)",
    fontFamily: "Poppins",
    fontSize: "14px",
  },
  exportBtn: {
    backgroundColor: "#79A1FA",
    padding: '0px 10px',
    // width: "160px",
    height: "58px",
  },
  tableHeads: {
    opacity: 1,
    fontWeight: 500,
    color: "rgba(178, 179, 189, 1)",
    fontFamily: "Poppins",
    fontSize: "12px",
  },
  bottom: {
    color: "#79A1FA",
  },
  tableDatas: {
    opacity: 1,
    fontWeight: 500,
    color: "rgba(17, 20, 45, 1)",
    fontFamily: "Poppins",
    fontSize: "14px",
  },
  loadMoreBtnTxt: {
    opacity: 1,
    fontWeight: 800,
    color: "rgba(255, 255, 255, 1)",
    fontFamily: "Poppins",
    fontSize: "14px",
  },
  loadMoreBtn: {
    width: "166px",
    height: "56px",
    backgroundColor: "#141618",
    borderRadius: "15px",
  },
  mainCard: {
    boxShadow: "10px 15px 15px #eaeaea, -10px -9px 15px #eaeaea",
  },
  fieldContainer: {
    marginTop: 'auto',
    minWidth: '100px',
    paddingRight: "0.7rem"
  },
  inputWhite: {
    backgroundColor: "white",
  },
  textRed: {
    color: "red",
  }
});

// Customizable Area End

export default withStyles(styles)(AdHocReporting);
