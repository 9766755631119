//@ts-nocheck
import React, { Component } from "react";
import {
  Grid,
  Button,
  Typography,
  Card,
  Theme,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Box,
  Avatar,
  IconButton,
  Drawer,
  Divider,
  TextField,
  Backdrop,
  Menu,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  ListItemIcon,
  ListItemText,
  Checkbox,
  InputAdornment,
} from "@material-ui/core";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import { withStyles } from "@material-ui/styles";
import ReactTooltip from "react-tooltip";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ShiftManagementController, { Props } from "./ShiftManagementController";
import "./UserManagement.web.css";

const backgroundImage = require("../assets/Background.png");
const shiftBorderImage = require("../assets/Group 8.png");
export class ShiftManagement extends ShiftManagementController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    //Customizable Area End
  }
  render() {
    const { navigation, classes } = this.props;
    //Customizable Area Start
    return (
      <>
        <Backdrop className={classes.backdrop} open={this.state.loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <ToastContainer />
        <Card className={classes.mainCard}>
          <Grid container spacing={2} style={{ padding: "20px" }}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={8}>
                  <Typography variant="body2" className={classes.text1}>
                    Shift Management
                  </Typography>
                </Grid>
                <Grid item xs={4} style={{ textAlign: "center" }}>
                  <Button
                    id="resetShiftBtn"
                    variant="contained"
                    className={classes.resetShiftBtn}
                    onClick={this.resetShifts}  
                  >
                    <Typography variant="body2" className={classes.text3}>
                      Reset Shift
                    </Typography>
                  </Button>
                  <Button
                    id="createShiftBtn"
                    variant="contained"
                    className={classes.createShiftBtn}
                    onClick={this.handleDrawer}
                  >
                    <Typography variant="body2" className={classes.text3}>
                      Create Shift
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ position: "relative", marginBottom: "35px", marginTop: "35px" }}
            >
              <img
                src={shiftBorderImage}
                style={{ width: "100%", position: "relative" }}
              />
              <div style={{ position: "absolute", top: "50%", left: "30px" }}>
                <span className={classes.text6}> No. of Shifts </span>
                <br />
                <span className={classes.text7}>
                  {this.state.shiftData
                    ? this.state.shiftData !== null
                      ? this.state.shiftData.length
                      : "0"
                    : "0"}{" "}
                  Shifts/day
                </span>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {this.state.shiftData.length !== 0 ? (
                  this.state.shiftData.map((_data: any) => {
                    return (
                        <Grid
                          key={_data.id}
                          item
                          xs={4}
                          style={{ marginTop: "30px" }}
                        >
                          <Card className={classes.shiftTimingCard}>
                            <Grid container>
                              <Grid item xs={10} style={{ padding: "20px" }}>
                                <Grid container>
                                  <Grid item xs={12}>
                                    <Typography
                                      variant="body2"
                                      className={classes.startTimeText}
                                    >
                                      Start Time
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Typography
                                      variant="body2"
                                      className={classes.textGreen}
                                    >
                                      {_data.startTime}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Typography
                                      variant="body2"
                                      className={classes.endTimeText}
                                    >
                                      End Time
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Typography
                                      variant="body2"
                                      className={classes.textRed}
                                    >
                                      {_data.endTime}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={2}>
                                <div
                                  style={{
                                    position: "absolute",
                                  }}
                                  className={classes.cardNumberText}
                                >
                                  {_data.cardNumber}
                                </div>
                              </Grid>
                            </Grid>
                          </Card>
                        </Grid>
                    );
                  })
                ) : (
                  <>
                    <span>No Shifts Found</span>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Card>

        <Drawer
          anchor="right"
          open={this.state.drawer}
          onClose={this.handleDrawer}
        >
          <Grid container spacing={2} style={{ width: "300px" }}>
            <Grid item xs={12}>
              <Grid container style={{ padding: "20px" }}>
                <Grid item xs={9}>
                  <Typography variant="body2" className={classes.text1}>
                    Create Shift
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Button
                    variant="contained"
                    className={classes.btnUserCancel1}
                    onClick={this.handleDrawer}
                  >
                    <span style={{ color: "#FFFFFF" }}>x</span>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2} style={{ padding: "20px" }}>
                <Grid item xs={12}>
                  <Typography variant="body2" className={classes.text4}>
                    Start Time
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="startTime"
                    fullWidth
                    variant="outlined"
                    type="time"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300, // 5 min
                    }}
                    value={this.state.startTime}
                    onChange={this.handleStartTime}
                    error={!this.state.isStartTimeValid ? true : false}
                    helperText={
                      <span
                        style={{
                          fontSize: "12px",
                          fontFamily: "Poppins",
                        }}
                      >
                        {this.state.startTimeErrorMessage}
                      </span>
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" className={classes.text4}>
                    End Time
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="endTime"
                    fullWidth
                    variant="outlined"
                    type="time"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300, // 5 min
                    }}
                    value={this.state.endTime}
                    onChange={this.handleEndTime}
                    error={!this.state.isEndTimeValid ? true : false}
                    helperText={
                      <span
                        style={{
                          fontSize: "12px",
                          fontFamily: "Poppins",
                        }}
                      >
                        {this.state.endTimeErrorMessage}
                      </span>
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    id="validateTimingBtn"
                    variant="outlined"
                    className={classes.DrawerBtnCreateShift}
                    fullWidth
                    onClick={this.validateTimings}
                    disabled={this.state.disableShiftBtn}
                  >
                    <Typography variant="body2" className={classes.text5}>
                      Create Shift
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Drawer>
      </>
    );
    //Customizable Area End
  }
}

// Customizable Area Start
const styles = (theme: Theme) => ({
  text1: {
    opacity: 1,
    fontWeight: 500,
    color: "rgba(17, 20, 45, 1)",
    fontFamily: "Poppins",
    fontSize: "25px",
  },

  text3: {
    opacity: 1,
    fontWeight: 500,
    color: "rgba(255, 255, 255, 1)",
    fontFamily: "Poppins",
    fontSize: "14px",
  },
  resetShiftBtn: {
    backgroundColor: "#F54E4F",
    width: "160px",
    height: "58px",
    borderRadius: "15px",
    marginRight: "15px"
  },
  createShiftBtn: {
    backgroundColor: "#79A1FA",
    width: "160px",
    height: "58px",
    borderRadius: "15px",
  },
  btnUserCancel1: {
    backgroundColor: "#79A1FA",
    minWidth: "0px",
    borderRadius: "20px",
  },
  text4: {
    opacity: 1,
    fontWeight: 500,
    color: "rgba(178, 179, 189, 1)",
    fontFamily: "Poppins",
    fontSize: "12px",
  },
  DrawerBtnCreateShift: {
    backgroundColor: "#79A1FA",
    height: "50px",
    borderRadius: "15px",
  },
  text5: {
    opacity: 1,
    fontWeight: 500,
    color: "#FFFFFF",
    fontFamily: "Poppins",
    fontSize: "15px",
  },
  backdrop: {
    zIndex: 9999,
    color: "#fff",
  },
  startTimeText: {
    fontFamily: "Poppins",
    fontWeight: 500,
    color: "#B5B6C0",
    fontSize: "16px",
  },
  endTimeText: {
    fontFamily: "Poppins",
    fontWeight: 500,
    color: "#B5B6C0",
    fontSize: "16px",
  },
  textGreen: {
    fontSize: "22px",
    fontFamily: "Poppins",
    fontWeight: 900,
    color: "#22C6A9",
  },
  textRed: {
    fontSize: "22px",
    fontFamily: "Poppins",
    fontWeight: 900,
    color: "#F54E4F",
  },
  cardNumberText: {
    fontFamily: "Poppins",
    fontWeight: 900,
    color: "#79A1FA",
    fontSize: "100px",
    marginTop: "-70px",
  },
  shiftTimingCard: {
    borderRadius: "15px",
    boxShadow: "10px 15px 15px #eaeaea",
  },
  resizeInput: {
    color: "red !important",
  },
  mainCard: {
    boxShadow: "10px 15px 15px #eaeaea, -10px -9px 15px #eaeaea",
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  shiftGrid: {
    border: "5px solid transparent",
    borderImage:
      "linear-gradient(to bottom right, #b827fc 0%, #2c90fc 25%, #b8fd33 50%, #fec837 75%, #fd1892 100%)",
    borderImageSlice: "1",
    height: "200px",
    margin: "20px auto",
    width: "200px",
  },
  text6: {
    fontFamily: "Poppins",
    fontWeight: 900,
    fontSize: "35px",
    color: "#6C5DD3",
  },
  text7: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "22px",
    color: "#68696C",
  },
});

// Customizable Area End

export default withStyles(styles(), { withTheme: true })(ShiftManagement);
