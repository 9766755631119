Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Settings";
exports.profileText = "Profile";
exports.yourProfileText = "Your Profile Image";
exports.infoText = "You can add new or remove profile Images at anytime"
exports.newPassword = "New Password"
exports.confirmPassword = "Confirm Password"
exports.updatePassword = "Update Password"

exports.notificationSettings = "Email Notification / Push Notification"
exports.defaultNotificationText = "Default notifications on user creation, shift creation etc"
exports.flowLimitText = "Flow Limit Notifications"
exports.loadLimitText = "Load Limit Notification"
exports.targetNotifyText = "Notify when target is completed"

exports.uploadNew = "Upload New"
exports.deleteImage = "Delete Image"

exports.labelBodyText = "Settings2 Body";

exports.btnExampleTitle = "CLICK ME";
exports.resetPasswordApiEndPoint = "update_password";
exports.uploadPhotoApiEndPoint = "upload_photo";
exports.apiGetType = "GET";
exports.apiPostType = "POST";
exports.apiPutType = "PUT";
exports.formDataContentType = "multipart/form-data";
exports.getUserProfileApiEndPoint = "show_profile";
exports.removePhotoApiEndPoint = "remove_photo";
exports.updateNotificationApiEndPoint = "update_notification_settings";
// Customizable Area End
