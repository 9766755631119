//@ts-nocheck
import React, { Component } from "react";
import {
  Grid,
  Container,
  Paper,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Badge
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import DashboardController, { Props } from "./DashboardController";
import { Link } from 'react-router-dom';

const SearchIcon = require("../assets/searchIcon.png");
const BellIcon = require("../assets/bellIcon.png");
const userIcon = require("../assets/userIcon.png");

export class Sidebar extends DashboardController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    //Customizable Area End
  }

  render() {
    const { navigation, classes } = this.props;

    return (
      <>
        <div className={classes.main2}>
          <Container maxWidth="xl">
            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={7}>
                    {/* <Paper
                      component="form"
                      className={classes.searchForm}
                      style={{
                        padding: "2px 4px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <IconButton
                        type="submit"
                        style={{ padding: "10px" }}
                        aria-label="search"
                      >
                        <img src={SearchIcon} width="15px" />
                      </IconButton>
                      <InputBase
                        style={{
                          marginLeft: 1,
                          flex: 1,
                          borderRadius: "50%",
                        }}
                        placeholder="Search"
                        inputProps={{ "aria-label": "search google maps" }}
                      />
                    </Paper> */}
                  </Grid>
                  <Link
                    to={`/Dashboard/Notifications`}
                    style={{
                      textDecoration: "none",
                      color: "black",
                    }}
                  >
                    <Grid item className={classes.bellIconDiv}>
                      <Badge color="secondary" badgeContent={this.props.notificationCount}>
                        <img id="bellIcon" src={BellIcon} width="25px" height="25px" />
                      </Badge>
                    </Grid>
                  </Link>
                  <Grid
                    item
                    onClick={this.openMenu}
                    className={classes.userIconDiv}
                    id="userIconMenu"
                  >
                    <img id="userIcon" className={classes.userIcon} src={this.state.userImg ? this.state.userImg : userIcon} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </div>
        {/* TopBarMenu */}
        <Menu
          id="headerSidebar-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          open={Boolean(this.state.anchorEl)}
          style={{ marginTop: "40px" }}
          onClose={this.handleMenuClose}
        >
          <MenuItem>
            <Link
              to={`/Dashboard/Settings2`}
              style={{
                textDecoration: "none",
                color: "black",
              }}
              id="idProfile"
            >Profile</Link>
          </MenuItem>
          {/* <MenuItem>My account</MenuItem> */}
          <MenuItem id="menuLogout" onClick={this.handleLogout}>Logout</MenuItem>
        </Menu>
      </>
    );
  }
}

// Customizable Area Start
export const styles = () => ({
  main2: {
    ['& .MuiContainer-root']: {
      padding: '0px 20px!important',
    },
    // backgroundImage: `url(${backgroundImage})`,
    paddingTop: "15px",
    paddingLeft: '30px',
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  searchForm: {
    boxShadow: 'none',
    // backgroundColor: "#e6e6e6",
    // borderRadius: 7,
    padding: "7px 20px",
  },
  bellIconDiv: {
    borderRadius: '50%',
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    padding: '7px',
    width: '39px',
    height: '39px',
    margin: '0 20px'
  },
  boldText: {
    fontWeight: 600,
  },
  userIconDiv: {
    borderRadius: '50%',
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",   
    width: '39px',
    height: '39px',     
  },
  userIcon: {
    height: "39px",
    width: "39px",
    borderRadius: "50%",
  },
});
// Customizable Area End

export default withStyles(styles)(Sidebar);
