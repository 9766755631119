//@ts-nocheck
import React, { Component } from 'react'
import {Grid, Container, Button,Typography,TextField } from "@material-ui/core";
import {withStyles } from "@material-ui/styles"

import ForgotPasswordController, { Props } from "./ForgotPasswordController";

const backgroundImage = require("../assets/background.png")
const esskayLogo = require("../assets/EsskaySystemsLogo.png");
const textBackground = require("../assets/HelloUserBackground.png")
const bannerImage = require("../assets/image_22.png")
const congratulations = require("../assets/congratulations.png")
const celebrate = require("../assets/celebrate.png")


export class ForgotPasswordSuccess extends ForgotPasswordController {
     constructor(props: Props) {
    super(props);
    //Customizable Area Start
    //Customizable Area End
  }
    render() {
        
         const { navigation,classes } = this.props;
        return (
                <div className={classes.main}>
                    <Grid container>
                        <Grid item xs={12} style={{paddingLeft:"20px"}}>
                            <img  style={{ height: "150px" }} src={esskayLogo} alt="EsskaySystemsLogo" className={classes.esskayLogo} />
                        </Grid>
                    </Grid>
                    <Container maxWidth="md">
                        <Grid container spacing={2}>
                            <Grid item xs={12} style={{textAlign:"center"}}>
                                <Typography variant="body2" className={classes.text2}>Congratulations!  <img src={celebrate} alt="EsskaySystemsLogo" className={classes.esskayLogo} /> </Typography>
                                </Grid>
                            <Grid item xs={12} style={{textAlign:"center"}}>
                                <Typography variant="body2" className={classes.text1}>You have Successfully Reset the Password. </Typography>
                                </Grid>
                            <Grid item xs={12} style={{textAlign:"center"}}>
                                  <img src={congratulations} alt="banner" className={classes.bannerImage} />
                                </Grid>
                            <Grid item xs={12} style={{textAlign:"center",padding:0}}>
                                  <Button id="navLoginBtn" variant="contained"   disableElevation className={classes.btnRoot} onClick={() => navigation.navigate("EmailAccountLoginBlock")} > 
                                        <span className={classes.text5}>Go to Login</span>
                                    </Button>
                                </Grid>
                        </Grid>
                    </Container>
                </div>            
        )
    }
}

// Customizable Area Start
const styles = () => ({
 gridSpacing:{
     height:"25px"
 },

 registeredIdText:{
    fontSize:"15px",
    color:"gray",
    fontWeight:700
 },
 btnRoot:{
     height:"50px",
     borderRadius:"15px !important",
     backgroundColor: "#79A1FA",
     width:"200px"
 },
 text1:{
     fontSize:"24px",
     fontFamily: 'Poppins ',
     color: 'rgba(17, 20, 45, 1)',
     fontWeight:500,
     opacity: 1,	
     backgroundColor: 'transparent'
 },
 text2:{
    opacity: 1,
    backgroundColor: 'transparent',
	color: 'rgba(17, 20, 45, 1)',
	fontFamily: 'Poppins',
	fontSize: "48px",
    fontWeight:600			
 },
 text3:{
		opacity: 1,
		backgroundColor: 'transparent',
		fontWeight: 800,
		color: "#79A1FA",
		fontFamily: 'AppleColorEmoji',
		fontSize: "40px"
 },
 text4:{
    color:"#B9BAC3",
	opacity: 1,
	backgroundColor: 'transparent',
	fontWeight: 600,
	fontFamily: 'Poppins',
	fontSize: "16px"
 },
    text5:{
        color:"#FFFFFF",
		opacity: 1,
        backgroundColor: 'transparent',
        fontFamily: 'Poppins',
        fontSize:"14px"	
    },
    text6:{
        color:"#11152C",
       
				opacity: 1,
				backgroundColor: 'transparent',
				fontFamily: 'Poppins',
				fontSize: "18px",
                fontWeight:500
    },
    text7:{
        
				opacity: 1,
				backgroundColor: 'transparent',
				fontStyle: 'normal',
				fontWeight: 500,
				color: 'rgba(119, 159, 253, 1)',
				
				fontFamily: 'Poppins',
				
    },
 main:{
     backgroundImage: `url(${backgroundImage})`,
     resizeMode: 'cover',
 },
 esskayLogo:{

 },
 wavingHand:{
     fontWeight:100
 },
 cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor:" #7AA2FA !important",
    }
  },
   notchedOutline: {
    borderWidth: '3px',
    borderColor: '#7AA2FA !important',
    
  },
   cssFocused: {},
   textFieldRoot:{
       [`& fieldset`]: {
            borderRadius: 10,
      },
   },
   textBackground:{
 backgroundImage: `url(${textBackground})`,
     resizeMode: 'cover',
    
   },
   bannerImage : {
    width:"80%",
    backgroundRepeat:"no-repeat",
    height:"90%"
   }

});

// Customizable Area End

export default withStyles(styles())(ForgotPasswordSuccess)
