//@ts-nocheck
import React, { Component } from "react";
import {
  Grid,
  Container,
  FormControl,
  Typography,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import DashboardController, { Props } from "./DashboardController";
import Sidebar from "./Sidebar.web";
const backgroundImage = require("../assets/d556836cdbf3f98427e2ff9a6d3a66e43cc7b98f.png");

export class Header extends DashboardController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    const { classes} = this.props;
    const { selectedDevice } = this.state;
    let deviceDetails: any = localStorage.getItem('userInfo');
    deviceDetails = localStorage.getItem('userInfo') ? JSON.parse(deviceDetails)?.data?.attributes?.devices?.data : [];

    const getDeviceName = () => {
      let subDevicesDetails: any;
      deviceDetails.map((item: any) => (item.attributes.device_imei_number == window.location.pathname.split("/")[3]) &&
        item.attributes.sub_devices.map((subItem: any) => subItem.sid == window.location.pathname.split("/")[4] ? subDevicesDetails = subItem.sid_name : 0));
      return subDevicesDetails;
    };
    let leftContent: any;
    if (window.location.pathname === '/Dashboard') {
      leftContent = <> <Typography variant="h5" component="div" className={classes.boldText}>
        Welcome to,
      </Typography>
        <Typography variant="h4" component="div" className={classes.boldText}>
          Remote Plant Management
        </Typography>
      </>
    } else if (window.location.pathname.split("/")[2] == 'DeviceInfo') {
      leftContent = <Typography variant="h5" component="div" className={classes.boldText}>
        <span className={classes.blueText}> Dashboard / </span>{getDeviceName()}
      </Typography>
    }

    return (
      <>
        <div className={classes.main}>
          <Container maxWidth="xl">
            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={8} className={classes.navLeftbar}>
                    <Grid container className={classes.marginYauto}>
                      <Grid item xs={12} style={{ padding: 0 }} >
                        {leftContent}
                      </Grid>
                    </Grid>                   
                  </Grid>
                  <Grid item xs={4}>
                    <Sidebar navigation={undefined} id={""} notificationCount={this.props.notificationCount} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </div>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles = () => ({
  main: {
    ['& .MuiContainer-root']: {
      padding: 0,
    },
    backgroundImage: `url(${backgroundImage})`,
    paddingTop: "15px",
    // paddingLeft: '24px',
    paddingRight: '24px',
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  navLeftbar: {
    ['& div']: {
      padding: '0 10px',
    },
    // padding: '0 15px',
    display: "flex"
    // borderRight: '2px solid lightgrey'
  },
  boldText: {
    fontWeight: 600,
  },
  blueText: {
    color: "#79A1FA",
  },
  marginYauto: {
    margin: "auto 0"
  }
});
// Customizable Area End

export default withStyles(styles)(Header);
