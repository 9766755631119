//@ts-nocheck
import React, { Component } from "react";
import {
  Grid,
  Container,
  Button,
  Typography,
  TextField,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import OtpInput from "react-otp-input";

import ForgotPasswordController, { Props } from "./ForgotPasswordController";

const backgroundImage = require("../assets/background.png");
const esskayLogo = require("../assets/EsskaySystemsLogo.png");
const textBackground = require("../assets/HelloUserBackground.png");
const bannerImage = require("../assets/image_22.png");

export class ForgotPasswordOTP extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    //Customizable Area End
  }
  render() {
    const { navigation, classes } = this.props;
    return (
      <>
        <div className={classes.main}>
          <Container maxWidth="xl">
            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                <Grid item xs={9}>
                  <Grid container>
                  <Grid item xs={3}>
                    <img
                      src={esskayLogo}
                      alt="EsskaySystemsLogo"
                      className={classes.esskayLogo}
                      style={{
                        width:"100%"
                      }}
                    />
                  </Grid>
                  </Grid>
                  <img
                      src={bannerImage}
                      alt="banner"
                      className={classes.bannerImage}
                    />
                  </Grid>
                  <Grid item xs={3} style={{paddingTop: '50px'}}>
                    <Grid container spacing={2}>
                      <div className={classes.textBackground}>
                        <Grid item xs={12}>
                          <Typography variant="h4" className={classes.text1}>
                            Hi User,
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="h4" className={classes.text2}>
                            Welcome{" "}
                            <span className={classes.wavingHand}>👋</span>
                          </Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.gridSpacing} />
                      </div>
                      <Grid item xs={12}>
                        <Typography variant="body2" className={classes.text3}>
                          OTP
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body2" className={classes.text4}>
                          Enter OTP we have sent you over your Email ID / Mobile Number.
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <OtpInput
                          id="otp_Input"
                          value={this.state.otp}
                          onChange={this.handleOtpChange}
                          numInputs={6}
                          inputStyle={classes.otpInput}
                        />
                        {this.state.isOtpValid ? (
                          ""
                        ) : (
                          <span className={classes.otpError}>
                            {this.state.otpErrorMessage}
                          </span>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          id="btnOtpSubmit"
                          variant="contained"
                          fullWidth
                          disableElevation
                          className={classes.btnRoot}
                          onClick={this.handleOtpSubmit}
                        >
                          <span className={classes.text5}>Submit</span>
                        </Button>
                      </Grid>
                      <Grid item xs={12} className={classes.gridSpacing} />
                      <Grid item xs={12}>
                        <Typography variant="body2" className={classes.text6}>
                          If you are Admin you can{" "}
                          <span>
                            {" "}
                            <a
                              target="_blank"
                              href="https://esskaysystems-86449-ruby.b86449.dev.eastus.az.svc.builder.cafe/admin"
                              className={classes.text7}
                            >
                              Login Here
                            </a>{" "}
                          </span>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </div>
      </>
    );
  }
}

// Customizable Area Start
const styles = () => ({
  gridSpacing: {
    height: "25px",
  },

  registeredIdText: {
    fontSize: "15px",
    color: "gray",
    fontWeight: 700,
  },
  btnRoot: {
    height: "50px",
    borderRadius: "15px !important",
    backgroundColor: "#79A1FA",
  },
  text1: {
    fontSize: "24px",
    fontFamily: "Poppins",
    color: "rgba(17, 20, 45, 1)",
    fontWeight: 500,
    opacity: 1,
    backgroundColor: "transparent",
  },
  text2: {
    opacity: 1,
    backgroundColor: "transparent",
    color: "rgba(17, 20, 45, 1)",
    fontFamily: "Poppins",
    fontSize: "48px",
    fontWeight: 600,
  },
  text3: {
    opacity: 1,
    backgroundColor: "transparent",
    fontWeight: 800,
    color: "#79A1FA",
    fontFamily: "AppleColorEmoji",
    fontSize: "40px",
  },
  text4: {
    color: "#B9BAC3",
    opacity: 1,
    backgroundColor: "transparent",
    fontWeight: 600,
    fontFamily: "Poppins",
    fontSize: "14px",
  },
  text5: {
    color: "#FFFFFF",
    opacity: 1,
    backgroundColor: "transparent",
    fontFamily: "Poppins",
    fontSize: "14px",
  },
  text6: {
    color: "#11152C",

    opacity: 1,
    backgroundColor: "transparent",
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: 500,
  },
  text7: {
    opacity: 1,
    backgroundColor: "transparent",
    fontStyle: "normal",
    fontWeight: 500,
    color: "rgba(119, 159, 253, 1)",

    fontFamily: "Poppins",
  },
  main: {
    backgroundImage: `url(${backgroundImage})`,
    resizeMode: "cover",
    paddingTop: "10px",
  },
  esskayLogo: {},
  wavingHand: {
    fontWeight: 100,
  },
  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: " #7AA2FA !important",
    },
  },
  notchedOutline: {
    borderWidth: "3px",
    borderColor: "#7AA2FA !important",
  },
  cssFocused: {},
  textFieldRoot: {
    [`& fieldset`]: {
      borderRadius: 10,
    },
  },
  otpInput: {
    /* width: 100% !important; */
    fontFamily: "Open sans",
    fontWeight: 600,
    fontSize: "24px",
    height: "auto",
    margin: "0px 10px 0px 5px",
    borderRadius: "8.8px",
    padding: "20px 10px 10px",
    border: "1px solid #CFCFCF",
    backgroundColor: "#F1F1F1",
  },
  bannerImage: {
    backgroundRepeat: "no-repeat",
  },
  otpError: {
    color: "red !important",
    paddingTop: "10px",
  },
});
// Customizable Area End

export default withStyles(styles)(ForgotPasswordOTP);
