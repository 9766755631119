//@ts-nocheck

import React, { Component } from "react";
import {
  Grid,
  Container,
  Button,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

const backgroundImage = require("../assets/background.png");
const esskayLogo = require("../assets/EsskaySystemsLogo.png");
const textBackground = require("../assets/HelloUserBackground.png");
const esskayImage = require("../assets/EsskaySystemsImage.png");
const esskayWavingHand = require("../assets/EsskaySystemsWavingHand.png");

export class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    //Customizable Area End
  }

  render() {
    const { navigation, classes } = this.props;

    return (
      <>
        <div className={classes.main}>
          <Container maxWidth="xl">
            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={9}>
                  <Grid container>
                  <Grid item xs={3}>
                    <img
                      src={esskayLogo}
                      style={{
                        width:"100%"
                      }}
                      alt="EsskaySystemsLogo"
                      className={classes.esskayLogo}
                    />
                  </Grid>
                  </Grid>
                    <img
                      src={esskayImage}
                      alt="EsskaySystems"
                      className={classes.esskayImage}
                    />
                  </Grid>
                  <Grid item xs={3} style={{paddingTop: '50px'}}>
                    <Grid container spacing={2}>
                      <div className={classes.textBackground}>
                        <Grid item xs={12}>
                          <Typography variant="h4" className={classes.text1}>
                            Hi User,
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="h4" className={classes.text2}>
                            Welcome
                            <span className={classes.wavingHand}>
                              <img src={esskayWavingHand} alt="Hi! Welcome" />
                            </span>
                          </Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.gridSpacing} />
                      </div>
                      <Grid item xs={12}>
                        <Typography variant="body2" className={classes.text3}>
                          Login
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body2" className={classes.text4}>
                          Enter your Email ID or Mobile Number
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="email_input"
                          variant="outlined"
                          fullWidth
                          className={classes.textFieldRoot}
                          error={!this.state.isEmailValid ? true : false}
                          helperText={
                            <span style={{ fontSize: "16px" }}>
                              {this.state.emailErrorMessage}
                            </span>
                          }
                          InputProps={{
                            classes: {
                              root: classes.cssOutlinedInput,
                              focused: classes.cssFocused,
                              notchedOutline: classes.notchedOutline,
                            },
                          }}
                          value={this.state.email}
                          onChange={this.handleEmailChange}
                        />
                        <small style={{ color: "#B9BAC3" }}>
                          Enter phone number with country code (Ex. 91XXXXXXXXXX)
                        </small>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body2" className={classes.text4}>
                          Password
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="password_input"
                          type={"password"}
                          variant="outlined"
                          fullWidth
                          className={classes.textFieldRoot}
                          InputProps={{
                            classes: {
                              root: classes.cssOutlinedInput,
                              focused: classes.cssFocused,
                              notchedOutline: classes.notchedOutline,
                            },
                          }}
                          value={this.state.password}
                          onChange={this.handlePasswordChange}
                        />
                      </Grid>
                      <Grid container item xs={12} justify="space-between">
                        <Grid item xs={6}>
                          <FormControlLabel
                            control={
                              <Checkbox

                              checked={this.state.checkedRememberMe}
                                onChange={this.handleRememberMeChange}
                                style={{
                                  color: "#79A1FA",
                                  marginLeft: "0.75rem",
                                }}
                              />
                            }
                            label="Remember"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            id="forgotPassword"
                            variant="body2"
                            className={classes.forgotPass}
                            onClick={() => this.goToForgotPassword}
                          >
                            <a
                              href="ForgotPassword"
                              style={{
                                float: "right",
                                textDecoration: "none",
                                color: "#79A1FA",
                              }}
                            >
                              Forgot Password
                            </a>
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          id="loginBtn"
                          variant="contained"
                          fullWidth
                          disableElevation
                          className={classes.btnRoot}
                          onClick={this.handleLoginClick}
                        >
                          <span className={classes.text5}>Login</span>
                        </Button>
                      </Grid>
                      <Grid item xs={12} className={classes.gridSpacing} />
                      <Grid item xs={12}>
                        <Typography variant="body2" className={classes.text6}>
                          If you are Admin you can{" "}
                          <span>
                            {" "}
                            <a
                              target="_blank"
                              href="https://esskaysystems-86449-ruby.b86449.dev.eastus.az.svc.builder.cafe/admin"
                              className={classes.text7}
                            >
                              Login Here
                            </a>{" "}
                          </span>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </div>
      </>
    );
  }
}

// Customizable Area Start
const styles = () => ({
  gridSpacing: {
    height: "25px",
  },
  registeredIdText: {
    fontSize: "15px",
    color: "gray",
    fontWeight: 700,
  },
  btnRoot: {
    height: "50px",
    borderRadius: "15px !important",
    backgroundColor: "#79A1FA",
  },
  text1: {
    fontSize: "24px",
    fontFamily: "Poppins-Medium",
    color: "rgba(17, 20, 45, 1)",
    fontWeight: 800,
    opacity: 1,
    backgroundColor: "transparent",
  },
  text2: {
    opacity: 1,
    backgroundColor: "transparent",
    color: "rgba(17, 20, 45, 1)",
    fontFamily: "Poppins-SemiBold",
    fontSize: "48px",
    fontWeight: 900,
  },
  text3: {
    opacity: 1,
    backgroundColor: "transparent",
    fontWeight: 800,
    color: "#79A1FA",
    fontFamily: "AppleColorEmoji",
    fontSize: "40px",
  },
  text4: {
    color: "#B9BAC3",
    opacity: 1,
    backgroundColor: "transparent",
    fontWeight: 900,
    fontFamily: "Poppins-SemiBold",
    fontSize: "16px",
  },
  text5: {
    color: "#FFFFFF",
    opacity: 1,
    backgroundColor: "transparent",
    fontFamily: "Poppins-Regular",
    fontSize: "14px",
  },
  text6: {
    color: "#11152C",
    opacity: 1,
    backgroundColor: "transparent",
    fontFamily: "Poppins-Regular",
    fontSize: "18px",
    fontWeight: 500,
  },
  text7: {
    opacity: 1,
    backgroundColor: "transparent",
    fontStyle: "normal",
    fontWeight: 500,
    color: "rgba(119, 159, 253, 1)",
    fontFamily: "Poppins-Regular",
  },
  forgotPass: {
    color: "#79A1FA",
    opacity: 1,
    backgroundColor: "transparent",
    fontWeight: 900,
    fontFamily: "Poppins-SemiBold",
    fontSize: "16px",
    marginTop: "0.6rem",
    marginRight: "0.75rem",
    cursor: "pointer",
  },
  main: {
    backgroundImage: `url(${backgroundImage})`,
    resizeMode: "cover",
    paddingTop: "10px",
  },
  esskayLogo: {},
  esskayImage: {},
  wavingHand: {
    fontWeight: 100,
  },
  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: " #7AA2FA !important",
    },
  },
  notchedOutline: {
    borderWidth: "3px",
    borderColor: "#7AA2FA !important",
  },
  cssFocused: {},
  textFieldRoot: {
    [`& fieldset`]: {
      borderRadius: 10,
    },
  },
  textBackground: {
    backgroundImage: `url(${textBackground})`,
  },
});
// Customizable Area End

export default withStyles(styles())(EmailAccountLoginBlock);
