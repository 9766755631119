//@ts-nocheck
import React, { Component } from "react";
import {
  Grid,
  Container,
  Button,
  Typography,
  TextField,
  Divider,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Card,
  CardContent,
  Backdrop,
  Theme,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Select,
  MenuItem,
  FormControl,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

import NotificationsController, { Props } from "./NotificationsController";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const userIcon = require("../../dashboard/assets/userIcon.png");

export class Notifications extends NotificationsController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    this.getNotifications();
    //Customizable Area End
  }
  render() {
    const { navigation, classes } = this.props;
 
    return (
      <>
        <ToastContainer />
        <Container maxWidth="xl">
          <Grid container>
            <Grid item xs={12} >
              <Card className={classes.card}>
                <CardContent>

                  <Grid container>
                    <Grid item xs={9}>
                      <Typography style={css.fontBold} gutterBottom variant="h5" component="div">
                        All Notifications
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <FormControl fullWidth>
                        <Select
                          id="notificationFilter"
                          value={"most_recent"}
                          className={classes.notificationFilter}
                          disableUnderline={true}
                          inputProps={{
                            classes: {
                              icon: classes.selectIcon,
                            },
                          }}
                        >
                          <MenuItem value={"most_recent"}>Filter By: Most Recent</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      {this.state.notificationData.length > 0 ? this.state.notificationData.map((item: any) => <Grid className={classes.contentContainer} container key={`Notifications${item.id}`}>
                          <Grid item xs={9}>
                            <Grid container>
                              <Grid item >
                                <img src={userIcon} width="60px" />
                              </Grid>
                              <Grid item xs={9}>
                                <Typography gutterBottom className={classes.title}>
                                  {item.attributes.headings}
                                </Typography>
                                <Typography gutterBottom className={classes.content}>
                                  {item.attributes.contents}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography gutterBottom style={{ textAlign: "right" }} className={classes.timing}>
                              {item.attributes.date} {item.attributes.time}
                            </Typography>
                          </Grid>
                        </Grid>) : <Typography style={css.fontBold} gutterBottom variant="h5" component="div">
                          No Notifications
                        </Typography>
                      }
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </>
    );
  }
}

// Customizable Area Start
export const styles = () => ({
  contentContainer: {
    ['& img']: {
      marginRight: "15px"
    },
    padding: "10px 0px"
  },
  card: {
    borderRadius: 20,
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    padding: "20px",
    marginBottom: '20px',
  },
  notificationFilter: {
    backgroundColor: '#79A1FA',
    color: 'white',
    padding: "10px 20px",
    borderRadius: 15,
    marginBottom: "10px",
    fontWeight: 700
  },
  selectIcon: {
    color: 'white'
  },
  title: {
    fontSize: '15px',
    fontWeight: 600,
    color: 'gray'
  },
  content: {
    fontWeight: 600
  },
  timing: {
    // textAlign: "right",
    color: "gray",
    fontWeight: 600,
  }
});

const css: { [key: string]: React.CSSProperties } = {
  fontBold: {
    fontWeight: "bold"
  }
};
// Customizable Area End

export default withStyles(styles)(Notifications);
