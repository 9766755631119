//@ts-nocheck

// Customizable Area Start

import moment from "moment";
import { Grid, Box, CircularProgress } from "@material-ui/core";
import React from "react";

import storage from "../../../framework/src/StorageProvider";

export const getUserType = () => {
    let userInformation: any = localStorage.getItem('userInfo');
    userInformation = JSON.parse(userInformation);
    const usetType = (userInformation?.data?.attributes?.role === 'CustomerAdmin'||userInformation?.data?.attributes?.role === 'Customer Admin') ? 'CustomerAdmin' : 'employee';
    return usetType;
}

export const getformatedCurrentDate = (type: any) => {
    const newDate = new Date();
    const todayDate = moment(newDate).format("YYYY-MM-DD");
    const todayTime = moment(newDate).format("HH:MM");
    // return (`${todayDate}T${todayTime}`);
    return (type === "time" ? `${todayDate}T${todayTime}` : todayDate);
}

// export const getformatedMonthBackDate = () => {
//     const today = new Date();
//     today.setMonth(today.getMonth() - 1)
//     const todayDate = moment(today).format("YYYY-MM-DD");
//     const todayTime = moment(today).format("HH:MM");
//     // return (`${todayDate}T${todayTime}`);
//     return (todayDate);
// }

export const getformatedYesterDayDate = (type: any) => {
    const newDate = new Date(new Date().getTime() - (24 * 60 * 60 * 1000));;
    const todayDate = moment(newDate).format("YYYY-MM-DD");
    const todayTime = moment(newDate).format("HH:MM");
    // return (`${todayDate}T${todayTime}`);    
    return (type === "time" ? `${todayDate}T${todayTime}` : todayDate);
}

export const pageLoader = () => {
    return (
        <Grid container style={{ display: "flex", width: '100%', minHeight: "200px" }}>
            <Box style={{ margin: 'auto' }}>
                <CircularProgress />
            </Box>
        </Grid>
    )
}

export const noDataFound = () => {
    return (
        <Grid container style={{ display: "flex", width: '100%', minHeight: "200px" }}>
            <Box style={{ margin: 'auto' }}>
                <h2>No Data Found</h2>
            </Box>
        </Grid>
    )
}

export const getUserInfo = (filterType: string) => {
    let userInformation: any = localStorage.getItem('userInfo');
    userInformation = JSON.parse(userInformation);
    const usetType = (userInformation?.data?.attributes?.role === 'CustomerAdmin' || userInformation?.data?.attributes?.role === 'Customer Admin') ? 'CustomerAdmin' : 'employee';

    if (filterType === 'subDevicesList') {
        let subDevicesList: any = [];
        if (usetType == 'CustomerAdmin') {
            userInformation?.data?.attributes?.devices?.data.map((item: any) => {
                item.attributes.sub_devices.map((subDevice: any) => subDevicesList.push(subDevice))
            });
        } else {
            userInformation?.data?.attributes?.devices?.data.map((item: any) => subDevicesList.push(item.attributes));
        }
        return subDevicesList;
    } else if (filterType === "userName") {
        return (`${userInformation?.data?.attributes.first_name} ${userInformation?.data?.attributes.last_name}`)
    } else {
        return userInformation;
    }
};


export const getDeviceDetailFromLocationObjBySid = async (imei: any, sid: any) => {
    let deviceList: any = localStorage.getItem('locationWiseDevices') || [];
    deviceList = JSON.parse(deviceList);
    let device ={};
    deviceList.map(( location: any ) => {
        Object.values(location).map((dev:any) => 
        dev.map((subDev:any) => {
            if(subDev.imei == imei && subDev.sub_device?.sid == sid ) {
                device= { ...subDev.sub_device, imei: imei };
            }
        })
        )
    })
    return device;
}

// export const getTimeDiffInsec = (d1: any, d2: any) => {
//     var date1 = new Date(d1);
//     var date2 = new Date(d2);
//     var diff = date2.getTime() - date1.getTime();
//     var msec = diff;
//     var hh = Math.floor(msec / 1000 / 60 / 60);
//     msec -= hh * 1000 * 60 * 60;
//     var mm = Math.floor(msec / 1000 / 60);
//     msec -= mm * 1000 * 60;
//     var ss = Math.floor(msec / 1000);
//     msec -= ss * 1000;
//     return ({ hrs: hh, min: mm, sec: ss });
// }

// this is demo one not exact one
// export const getTimeDiffByMqtt = async (imei: any, sid: any, type: string) => {
//     const deviceObj = await getMqttDataByImeiSid(imei, sid);
//     var date1 = new Date();
//     if (type === "start") {
//         date1 = new Date("Thu Jan 06 2022 17:12:06 GMT+0530 (India Standard Time)");
//     } else if (type === "idle") {
//         date1 = new Date("Thu Jan 06 2022 17:12:06 GMT+0530 (India Standard Time)");
//     } else if (type === "down") {
//         date1 = new Date("Thu Jan 06 2022 17:12:06 GMT+0530 (India Standard Time)");
//     }
//     var date2 = new Date();
//     var diff = date2.getTime() - date1.getTime();
//     var msec = diff;
//     var hh = Math.floor(msec / 1000 / 60 / 60);
//     msec -= hh * 1000 * 60 * 60;
//     var mm = Math.floor(msec / 1000 / 60);
//     msec -= mm * 1000 * 60;
//     var ss = Math.floor(msec / 1000);
//     msec -= ss * 1000;
//     return ({ hrs: hh, min: mm, sec: ss })
// };

export const getTimeObjByTimeString = async (time: string) => {
    const timeOBj = time.split(":")
    return ({ hrs: timeOBj[0] ? parseInt(timeOBj[0]) : 0, min: timeOBj[1] ? parseInt(timeOBj[1]) : 0 })
};



export const getUserTypeMob = async () => {
    let userInformation = await getUserInfoMob();
    console.log("information:0",userInformation)
    const userType = (userInformation?.data?.attributes?.role === 'CustomerAdmin'||userInformation?.data?.attributes?.role === 'Customer Admin') ? 'CustomerAdmin' : 'employee';
    console.log("type uset",userType)
    return userType;
}
export const getTimeObj = (time: string) => {
    const timeOBj = time ? time.split(":") : ['00','00','00'];
    let hours = timeOBj[0] < 10 ? `0${parseInt(timeOBj[0])}` : parseInt(timeOBj[0]);
    let mins = timeOBj[1]<10 ? `0${parseInt(timeOBj[1])}` :parseInt(timeOBj[1]);
    return ({ hrs: timeOBj[0] ? hours:0, min: timeOBj[1] ? mins : 0 })
};
export const getInitialValue = (value: any) => {
    return !value ? 0.00 : value
};
const getUserInfoMob = async ()=>{
    let userInfo:any = await storage?.get('userInfo');
    userInfo = userInfo ? JSON.parse(userInfo): null;
    console.log("promise123",userInfo)
    return ({data:userInfo?.data});
}
export const getUserInfoForMob = async (type: string) => {
    let userInformation = await getUserInfoMob();
    const userType = (userInformation?.data?.attributes?.role === 'CustomerAdmin' || userInformation?.data?.attributes?.role === 'Customer Admin') ? 'CustomerAdmin' : 'employee';
    console.log("type of user",userType);
    console.log("userInfo",userInformation)
    if (type === 'devices') {
        return userInformation?.data?.attributes?.devices?.data ;
    } else if (type === 'subDevicesList') {
        let subDevicesList: any = [];
        if (userType == 'CustomerAdmin') {
            userInformation?.data?.attributes?.devices?.data.map((item: any) => {
                item.attributes.sub_devices.map((subDevice: any) => subDevicesList.push(subDevice))
            });
        } else {
            userInformation?.data?.attributes?.devices?.data.map((item: any) => subDevicesList.push(item.attributes));
        }
        return subDevicesList;
    } else if (type === "userName") {
        return (`${userInformation?.data?.attributes.first_name} ${userInformation?.data?.attributes.last_name}`)
    } else if (type === "userData") {
        if (userType == 'CustomerAdmin') {
            return userInformation?.data?.attributes;
        }
        // return userInformation;
    } else {
        return userInformation;
    }
};
export const getDeviceDetailsBySidMob = async (imei: any, sid: any) => {
    let deviceList: any = await getUserInfoForMob('devices');
    const userType = await getUserTypeMob();
    let subDeviceObj: any = {};

    if (userType == 'CustomerAdmin') {
        for (let item of deviceList) {
            if (item.attributes.device_imei_number == imei) {
                const deviceInfo = item.attributes.sub_devices.filter((subDevice: any) => subDevice.sid == sid);
                if (deviceInfo?.length > 0) {
                    subDeviceObj = deviceInfo[0];
                    break;
                }

            }
        }

    } else {
        deviceList?.map((item: any) => {
            if (item.attributes.imei_number == imei && item.attributes.sid == sid) {
                subDeviceObj = item.attributes;
            }
        })
    }

    console.log("getDeviceDetailsBySidMob: ", subDeviceObj)
    return subDeviceObj;
};


// export const getMqttSubDeviceDataBySid = (sid: any) => {
//     let devicesMqtt: any = localStorage.getItem('devicesMqtt');
//     let subDeviceDataByID: any = { sid: sid };
//     devicesMqtt = localStorage.getItem('devicesMqtt') ? JSON.parse(devicesMqtt) : [];
//     devicesMqtt.length > 0 && devicesMqtt.map((device: any) => {
//         device.subDevices.map((subDevice: any) => {
//             if (subDevice == sid) {
//                 subDeviceDataByID = subDevice;
//             }
//         })
//     })
//     return subDeviceDataByID;
// };

export const getFormatedDate = (dateString: any) => {
    const gevenDate = new Date(dateString);
    return ({ date: dateString ? gevenDate.toLocaleDateString() : "--/--/--", time: dateString ? gevenDate.toLocaleTimeString() : "--:--" })
}

// export const getTimeBystring = (dateString: any) => {
//     return !dateString || dateString !== 0 ? "--:--" : moment(dateString).format('hh:mm A');
// };

// export const getTimeFromString = () => {

// };

export const skipped = (ctx: any, value: any) => ctx.p0.skip || ctx.p1.skip ? value : undefined;
export const down = (ctx: any, value: any) => ctx.p0.parsed.y > ctx.p1.parsed.y ? value : undefined;

// Customizable Area End
