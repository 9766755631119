// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';

import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import PhoneNumberInput from '../../blocks/mobile-account-registration/src/mobile-input/PhoneNumberInput';
import AdditionalDetailForm from '../../blocks/mobile-account-registration/src/user-additional-detail-input/AdditionalDetailForm';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLoginBlock';
import EmailAccountLoginWeb from '../../blocks/email-account-login/src/EmailAccountLoginBlock.web';
import Dashboard from '../../blocks/dashboard/src/Dashboard.web';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth';
import CfTargetSetting2 from '../../blocks/CfTargetSetting2/src/CfTargetSetting2';
import CfProtocolParsing3 from '../../blocks/CfProtocolParsing3/src/CfProtocolParsing3';
import SocialMediaAccountRegistrationScreen from '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen';
import RolesPermissions2 from '../../blocks/RolesPermissions2/src/RolesPermissions2';
import AdminConsole3 from '../../blocks/AdminConsole3/src/AdminConsole3';
import Location4 from '../../blocks/Location4/src/Location4';
import AdHocReporting from '../../blocks/AdHocReporting/src/AdHocReporting';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import MobileAccountLoginBlock from '../../blocks/mobile-account-login/src/MobileAccountLoginBlock';
import GraphicalCharts from '../../blocks/GraphicalCharts/src/GraphicalCharts';
import Settings2 from '../../blocks/Settings2/src/Settings2.web';
import Notifications from '../../blocks/Notifications/src/Notifications';
import CfRuntimeTotal2 from '../../blocks/CfRuntimeTotal2/src/CfRuntimeTotal2';
import CfRestApi3 from '../../blocks/CfRestApi3/src/CfRestApi3';
import UserProfileBasicBlock from '../../blocks/user-profile-basic/src/UserProfileBasicBlock.web';
import PeopleManagement2 from '../../blocks/PeopleManagement2/src/PeopleManagement2';
import EmailNotifications from '../../blocks/EmailNotifications/src/EmailNotifications';
import CfMqttConsumer3 from '../../blocks/CfMqttConsumer3/src/CfMqttConsumer3';
import DataImportexportcsv from '../../blocks/DataImportexportcsv/src/DataImportexportcsv';
import PdfConvert from '../../blocks/PdfConvert/src/PdfConvert';
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword';
import ForgotPasswordWeb from '../../blocks/forgot-password/src/ForgotPassword.web';
import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOTP.web';
import NewPassword from '../../blocks/forgot-password/src/NewPassword.web';
import SocialMediaAccountLoginScreen from '../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen';
import PushNotifications from '../../blocks/PushNotifications/src/PushNotifications';
import TwofactorAuthentication from '../../blocks/TwofactorAuthentication/src/TwofactorAuthentication';
import ForgotPasswordSuccess from '../../blocks/forgot-password/src/ForgotPasswordSuccess.web';
import UserManagement from '../../blocks/user-profile-basic/src/UserManagement.web';

const routeMap = {
  // core:{
  //  component:core,
  // path:"/core"},
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: '/PhoneNumberInput'
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: '/AdditionalDetailForm'
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginWeb,
    path: '/EmailAccountLoginBlock'
  },
  Dashboard: {
    component: Dashboard,
    path: '/Dashboard'
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: '/EmailAccountRegistration'
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth'
  },
  CfTargetSetting2: {
    component: CfTargetSetting2,
    path: '/CfTargetSetting2'
  },
  CfProtocolParsing3: {
    component: CfProtocolParsing3,
    path: '/CfProtocolParsing3'
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: '/SocialMediaAccountRegistrationScreen'
  },
  RolesPermissions2: {
    component: RolesPermissions2,
    path: '/RolesPermissions2'
  },
  AdminConsole3: {
    component: AdminConsole3,
    path: '/AdminConsole3'
  },
  Location4: {
    component: Location4,
    path: '/Location4'
  },
  AdHocReporting: {
    component: AdHocReporting,
    path: '/AdHocReporting'
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector'
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: '/MobileAccountLoginBlock'
  },
  GraphicalCharts: {
    component: GraphicalCharts,
    path: '/GraphicalCharts'
  },
  Settings2: {
    component: Settings2,
    path: '/Settings2'
  },
  Notifications: {
    component: Notifications,
    path: '/Notifications'
  },
  CfRuntimeTotal2: {
    component: CfRuntimeTotal2,
    path: '/CfRuntimeTotal2'
  },
  CfRestApi3: {
    component: CfRestApi3,
    path: '/CfRestApi3'
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: '/UserProfileBasicBlock'
  },
  PeopleManagement2: {
    component: PeopleManagement2,
    path: '/PeopleManagement2'
  },
  EmailNotifications: {
    component: EmailNotifications,
    path: '/EmailNotifications'
  },
  CfMqttConsumer3: {
    component: CfMqttConsumer3,
    path: '/CfMqttConsumer3'
  },
  DataImportexportcsv: {
    component: DataImportexportcsv,
    path: '/DataImportexportcsv'
  },
  PdfConvert: {
    component: PdfConvert,
    path: '/PdfConvert'
  },
  // core:{
  //  component:core,
  // path:"/core"},
  ForgotPassword: {
    component: ForgotPasswordWeb,
    path: '/ForgotPassword'
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP'
  },
  NewPassword: {
    component: NewPassword,
    path: '/NewPassword'
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: '/SocialMediaAccountLoginScreen'
  },
  PushNotifications: {
    component: PushNotifications,
    path: '/PushNotifications'
  },
  TwofactorAuthentication: {
    component: TwofactorAuthentication,
    path: '/TwofactorAuthentication'
  },

  Home: {
    component: EmailAccountLoginBlock,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  },
  ForgotPasswordSuccess: {
    component: ForgotPasswordSuccess,
    path: '/ForgotPasswordSuccess'
  },
  UserManagement: {
    component: UserManagement,
    path: '/UserManagement'
  }
};

class App extends Component {

  render() {
    // defaultAnalytics.logEvent('APP_Loaded');

    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        {/* <TopNav /> */}
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
