//@ts-nocheck
import React, { Component } from "react";
import {
  Grid,
  Container,
  Button,
  Typography,
  TextField,
  Divider,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Card,
  CardContent,
  Backdrop,
  Theme,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

import Settings2Controller, { Props } from "./Settings2Controller";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dropzone from "react-dropzone";
import { pageLoader } from "../../dashboard/src/utility";

const avatar = require("../assets/avatar.png");

export class Settings2 extends Settings2Controller {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    //Customizable Area End
  }
  render() {
    const { navigation, classes } = this.props;
    const { userData } = this.state;
    return (
      <>
        {/* <Backdrop className={classes.backdrop} open={this.state.loading}>
          <CircularProgress color="inherit" />
        </Backdrop> */}

        <ToastContainer />
        <Card className={classes.mainCard}>
          {this.state.loading ? pageLoader() : <Grid container spacing={2} className={classes.container}>
            <Grid item xs={12} style={{ height: "10px" }} />
            <Grid item xs={12}>
              <Typography variant="body2" className={classes.profileText1}>
                Profile
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" className={classes.profileText2}>
                Your Profile Image
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item className={classes.profileDetails}>
                  <img
                    src={userData?.attributes?.photo || avatar}
                    alt="avatar"
                    className={classes.avatar}
                  />
                </Grid>
                <Grid item xs={8}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        className={classes.uploadButton}
                        component="label"
                      >
                        <span className={classes.uploadText}>Upload New</span>

                        <Dropzone
                          onDrop={(acceptedFiles: any) =>{
                             return this.handleFileChange(acceptedFiles)}
                          }
                        >
                          {({ getRootProps, getInputProps }) => (
                            <section>
                              <div {...getRootProps()}>
                                <input {...getInputProps()} />
                              </div>
                            </section>
                          )}
                        </Dropzone>
                      </Button>
                      <Button
                        id="handleDialogBtn"
                        variant="contained"
                        className={classes.deleteButton}
                        onClick={this.handleCloseDialog}
                      >
                        <span className={classes.deleteText}>Delete Image</span>
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2" className={classes.text1}>
                        You can add new or remove profile images at anytime.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {userData?.attributes?.first_name &&
                <Grid container>
                  <Grid item>
                    <Typography variant="body2" className={classes.profileDetails}>
                      Name :
                    </Typography>
                  </Grid>
                  <Grid item xs={8} style={{ paddingLeft: 5 }}>
                    <Typography variant="body2" className={classes.profileDetailsValue}>
                      {userData?.attributes?.first_name} {userData?.attributes?.last_name}
                    </Typography>
                  </Grid>
                </Grid>}

              {userData?.attributes?.email && <Grid container>
                <Grid item>
                  <Typography variant="body2" className={classes.profileDetails}>
                    Email :
                  </Typography>
                </Grid>
                <Grid item xs={8} style={{ paddingLeft: 5 }}>
                  <Typography variant="body2" className={classes.profileDetailsValue}>
                    {userData?.attributes?.email}
                  </Typography>
                </Grid>
              </Grid>}

              {userData?.attributes?.full_phone_number && <Grid container>
                <Grid item>
                  <Typography variant="body2" className={classes.profileDetails}>
                    Mobile :
                  </Typography>
                </Grid>
                <Grid item xs={8} style={{ paddingLeft: 5 }}>
                  <Typography variant="body2" className={classes.profileDetailsValue}>
                    {userData?.attributes?.full_phone_number}
                  </Typography>
                </Grid>
              </Grid>}

              {userData?.attributes?.company_name && <Grid container>
                <Grid item>
                  <Typography variant="body2" className={classes.profileDetails}>
                    Company Name :
                  </Typography>
                </Grid>
                <Grid item xs={8} style={{ paddingLeft: 5 }}>
                  <Typography variant="body2" className={classes.profileDetailsValue}>
                    {userData?.attributes?.company_name}
                  </Typography>
                </Grid>
              </Grid>}

              {userData?.attributes?.company_address && <Grid container>
                <Grid item>
                  <Typography variant="body2" className={classes.profileDetails}>
                    Company Address :
                  </Typography>
                </Grid>
                <Grid item xs={8} style={{ paddingLeft: 5 }}>
                  <Typography variant="body2" className={classes.profileDetailsValue}>
                    {userData?.attributes?.company_address}
                  </Typography>
                </Grid>
              </Grid>}

              {userData?.attributes?.activation_date && <Grid container>
                <Grid item>
                  <Typography variant="body2" className={classes.profileDetails}>
                    Date of Activation :
                  </Typography>
                </Grid>
                <Grid item xs={8} style={{ paddingLeft: 5 }}>
                  <Typography variant="body2" className={classes.profileDetailsValue}>
                    {userData?.attributes?.activation_date}
                  </Typography>
                </Grid>
              </Grid>}

              {userData?.attributes?.renewal_date && <Grid container>
                <Grid item>
                  <Typography variant="body2" className={classes.profileDetails}>
                    Date of next Renewal.  :
                  </Typography>
                </Grid>
                <Grid item xs={8} style={{ paddingLeft: 5 }}>
                  <Typography variant="body2" className={classes.profileDetailsValue}>
                    {userData?.attributes?.renewal_date}
                  </Typography>
                </Grid>
              </Grid>}
            </Grid>

            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={3}>
                  <Typography variant="body2" className={classes.text2}>
                    New Password
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body2" className={classes.text2}>
                    Confirm Password{" "}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={3}>
                  <TextField
                    id="newPassword"
                    variant="outlined"
                    type="password"
                    value={this.state.newPassword}
                    onChange={this.handleNewPasswordChange}
                    error={!this.state.isNewPasswordValid ? true : false}
                    helperText={
                      <span style={{ fontSize: "16px" }}>
                        {this.state.newPasswordErrorMessage}
                      </span>
                    }
                    InputProps={{
                      classes: {
                        root: classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    id="conFirmPassword"
                    variant="outlined"
                    helperText={
                      <span style={{ fontSize: "16px" }}>
                        {this.state.confirmPasswordErrorMessage}
                      </span>
                    }
                    error={!this.state.isConfirmPasswordValid ? true : false}
                    type="password"
                    value={this.state.confirmPassword}
                    onChange={this.handleConfirmPasswordChange}
                    InputProps={{
                      classes: {
                        root: classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline,
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" className={classes.text8}>
                NOTE: Password should contain 1 capital letter, 1 special
                character & mininum 6 letters.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                className={classes.updateButton}
                onClick={this.handleResetPasswordClick}
                id="resetPasswordBtn"
              >
                <span style={{ color: "#FFFFFF", fontFamily: "Poppins" }}>
                  Update Password
                </span>
              </Button>
            </Grid>
            <Grid item xs={12} style={{ marginTop: "20px" }}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" className={classes.text3}>
                Email Notification / Push Notification
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="handleNotification"
                      name="enable_target_complete"
                      style={{
                        color: "#79A1FA",
                      }}
                      checked={this.state.targetNotify}
                      onChange={this.handleNotificationCheckbox}
                    />
                  }
                  label={
                    <Typography variant="body2" className={classes.text4}>
                      Notify when target is completed
                    </Typography>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="enable_flow_limit"
                      style={{
                        color: "#79A1FA",
                      }}
                      checked={this.state.enableFlowLimit}
                      onChange={this.handleNotificationCheckbox}
                    />
                  }
                  label={
                    <Typography variant="body2" className={classes.text4}>
                      Flow Limit Notifications
                    </Typography>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="enable_load_limit"
                      style={{
                        color: "#79A1FA",
                      }}
                      checked={this.state.enable_load_limit}
                      onChange={this.handleNotificationCheckbox}
                    />
                  }
                  label={
                    <Typography variant="body2" className={classes.text4}>
                      Load Limit Notifications
                    </Typography>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="enable_notification"
                      style={{
                        color: "#79A1FA",
                      }}
                      checked={this.state.pushNotification}
                      onChange={this.handleNotificationCheckbox}
                    />
                  }
                  label={
                    <Typography variant="body2" className={classes.text4}>
                      Default notifications on user creation, shift creation etc
                    </Typography>
                  }
                />

              </FormGroup>
            </Grid>
          </Grid>}
        </Card>

        {/* Confirm Dialog Start */}

        <Dialog
          open={this.state.openDialog}
          onClose={this.handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            style={{
              fontFamily: "Poppins !important",
              fontSize: "12px",
              fontWeight: 500,
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: "normal",
              letterSpacing: "0px",
              color: "#79A1FA",
              opacity: 1,
              textTransform: "uppercase",
            }}
            id="alert-dialog-title"
          >
            Confirmation
          </DialogTitle>
          <DialogContent
            style={{
              width: "450px",
              height: "65px",
            }}
          >
            <DialogContentText id="alert-dialog-description">
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    Are you sure you want to delete ?
                  </Typography>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ marginBottom: "20px" }}>
            <Button color="primary" onClick={this.handleCloseDialog}>
              <Typography className={classes.dialogButton} variant="body2">
                Cancel
              </Typography>
            </Button>
            <Button>
              <Typography
                id="deleteProfileImageBtn"
                className={classes.dialogButton}
                variant="body2"
                onClick={this.deleteProfileImage}
              >
                Delete
              </Typography>
            </Button>
          </DialogActions>
        </Dialog>
        {/* Confirm Dialog End */}
      </>
    );
  }
}

// Customizable Area Start
export const styles = (theme: Theme) => ({
  container: {
    background: "white",
    borderRadius: "10px",
    padding: "20px",
  },
  profileText1: {
    opacity: 1,
    fontWeight: 600,
    color: "rgba(17, 20, 45, 1)",
    fontFamily: "Poppins",
    fontSize: "18px",
  },
  profileDetailsValue: {
    // textTransform: 'capitalize',
    fontSize: "14px",
    fontWeight: 500,
    color: "rgba(178, 179, 189, 1)",
    marginBottom: "8px"
    // fontFamily: "Poppins",
  },
  profileDetails: {
    fontWeight: 500,
    color: "rgba(178, 179, 189, 1)",
    // fontFamily: "Poppins",
    fontSize: "14px",
    minWidth: "150px",
  },
  profileText2: {
    fontSize: "15px",
    opacity: 1,
    color: "rgba(178, 179, 189, 1)",
    fontFamily: "Poppins",
    fontWeight: 500,
  },
  uploadButton: {
    backgroundColor: "#79A1FA",
    width: "170px",
    borderRadius: "22px",
    height: "40px",
  },
  deleteButton: {
    marginLeft: "20px",
    width: "170px",
    borderRadius: "22px",
    height: "40px",
  },
  uploadText: {
    color: "#FFFFFF",
    fontSize: "14px",
    fontFamily: "Poppins",
    fontWeight: 500,
  },
  deleteText: {
    color: "black",
    fontSize: "14px",
    fontFamily: "Poppins",
    fontWeight: 500,
  },
  updateButton: {
    width: "190px",
    borderRadius: "22px",
    height: "40px",
    backgroundColor: "#79A1FA",
  },
  text8: {
    opacity: 1,
    backgroundColor: "transparent",
    color: "#B9BAC3",
    fontFamily: "Poppins",
    fontSize: "10px",
    fontWeight: 600,
  },
  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: " #7AA2FA !important",
    },
  },
  notchedOutline: {
    borderWidth: "3px",
    borderColor: "#7AA2FA !important",
  },
  cssFocused: {},
  textFieldRoot: {
    [`& fieldset`]: {
      borderRadius: 10,
    },
  },
  text1: {
    opacity: 1,
    fontWeight: 500,
    color: "rgba(178, 179, 189, 1)",
    fontFamily: "Poppins",
    fontSize: "14px",
  },
  text2: {
    opacity: 1,
    fontWeight: 500,
    color: "rgba(178, 179, 189, 1)",
    fontFamily: "Poppins",
    fontSize: "12px",
  },
  text3: {
    opacity: 1,
    fontWeight: 500,
    color: "rgba(17, 20, 45, 1)",
    fontFamily: "Poppins",
    fontSize: "18px",
  },
  text4: {
    opacity: 1,
    fontWeight: 500,
    color: "rgba(128, 129, 145, 1)",
    fontFamily: "Poppins",
    fontSize: "14px",
  },
  backdrop: {
    zIndex: 9999,
    color: "#fff",
  },
  dialogButton: {
    fontSize: "14px",
    fontFamily: "Poppins",
    letterSpacing: "0px",
    opacity: 1,
    color: "#79A1FA",
  },
  avatar: {
    height: "70px",
    width: "75px",
    borderRadius: "35px",
  },
  mainCard: {
    // boxShadow: "10px 15px 15px #eaeaea, -10px -9px 15px #eaeaea",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    borderRadius: 20,
    margin: "0px 15px"
  },
});

// Customizable Area End

export default withStyles(styles())(Settings2);
