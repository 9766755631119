//@ts-nocheck
import React, { Component } from "react";
import {
  Grid,
  Container,
  Button,
  Typography,
  TextField,
  Card,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import Select from "react-select";

import UserProfileBasicBlockController, {
  Props,
} from "./UserProfileBasicController";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

const customStyles = {
  control: (base: any) => ({
    ...base,
    height: 57,
    minHeight: 35,
  }),
};

export class UserProfileBasicBlock extends UserProfileBasicBlockController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    //Customizable Area End
  }
  render() {
    const { navigation, classes } = this.props;
    return (
      <>
        <Card className={classes.mainCard}>
          <Grid container spacing={2} style={{ padding: "20px" }}>
            <Grid item xs={12}>
              <Typography variant="body2" className={classes.text1}>
                User Profile
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField variant="outlined" label="First Name" fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField variant="outlined" label="Last Name" fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField variant="outlined" label="Email" fullWidth />
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                type="password"
                label="Password"
                fullWidth
              />
            </Grid> */}
            <Grid item xs={12} sm={6}>
              <TextField variant="outlined" label="Mobile" fullWidth />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Select
                id="selectDevices"
                options={this.state.deviceList}
                isMulti
                name="Devices"
                styles={customStyles}
                placeholder={
                  <span className={classes.text2}>Select Device</span>
                }
                onChange={this.handleSubDeviceChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Select
                options={options}
                styles={customStyles}
                placeholder={
                  <span className={classes.text2}>Select Shift Timing</span>
                }
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Button variant="contained" className={classes.saveBtn}>
                <Typography variant="body2" className={classes.saveBtnText}>
                  Save
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Card>
      </>
    );
  }
}

// Customizable Area Start
const styles = () => ({
  text1: {
    fontSize: "20px",
  },
  text2: {
    fontFamily: "Poppins",
  },
  saveBtn: {
    width: "166px",
    height: "56px",
    backgroundColor: "#141618",
    borderRadius: "15px",
  },
  saveBtnText: {
    opacity: 1,
    fontWeight: 800,
    color: "rgba(255, 255, 255, 1)",
    fontFamily: "Poppins",
    fontSize: "14px",
  },
  mainCard: {
    boxShadow: "10px 15px 15px #eaeaea, -10px -9px 15px #eaeaea",
  },
});

// Customizable Area End

export default withStyles(styles())(UserProfileBasicBlock);
