//@ts-nocheck
import React, { Fragment, useCallback } from "react";
import {
  Grid,
  Container,
  Card,
  CardContent,
  FormControl,
  TextField,
  Button,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
// import { getDeviceDetailsBySid } from './utility';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DashboardController, { Props } from "./DashboardController";

export class TargetSettingsForm extends DashboardController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    this.getTargetSettings();
    //Customizable Area End
  }

  render() {
    const { classes } = this.props;

    const handleChanger = (event: any) => {
      const targetData = { ...this.state.targetData };
      targetData[event.target.name] = event.target.value ? parseInt(event.target.value) : ""
      this.setState({ targetData: targetData });
    };

    return (
      <Fragment>
        <Card className={classes.card}>
          <CardContent >
            <ToastContainer />
            <Container style={{ padding: 0 }} maxWidth="xl">
              <Grid container>
                <Grid item xs={12} style={{ padding: "0 10px" }}>
                  <h1>Target Settings</h1>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth className={classes.formController}>

                    <TextField
                      fullWidth
                      variant="standard"
                      label="Min Flow"
                      type="number"
                      name="min_flow"
                      id="targetFormMin_flow"
                      className={classes.fieldInput1}
                      InputLabelProps={{
                        shrink: true,
                        classes: {
                          root: classes.labelProps
                        }
                      }}
                      InputProps={{
                        disableUnderline: true,
                        classes: { root: classes.cssOutlinedInput },
                      }}
                      error={this.state.targetData?.error_min_flow}
                      helperText={
                        <span style={{ fontSize: "12px", color: 'red' }}>
                          {this.state.targetData?.error_min_flow && "Please provide a valid value"}
                        </span>
                      }
                      value={this.state.targetData.min_flow}
                      onChange={handleChanger}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={4}>
                  <FormControl fullWidth className={classes.formController}>
                    <TextField
                      fullWidth
                      variant="standard"
                      label="Max Flow"
                      name="max_flow"
                      type="number"
                      id="targetFormmax_flow"
                      className={classes.fieldInput1}
                      InputLabelProps={{
                        shrink: true,
                        classes: {
                          root: classes.labelProps
                        }
                      }}
                      InputProps={{
                        disableUnderline: true,
                        classes: { root: classes.cssOutlinedInput },
                      }}
                      value={this.state.targetData.max_flow}
                      onChange={handleChanger}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={4}>
                  <FormControl fullWidth className={classes.formController}>
                    <TextField
                      fullWidth
                      variant="standard"
                      label="Min Speed"
                      name="min_speed"
                      type="number"
                      id="targetFormmin_speed"
                      className={classes.fieldInput1}
                      InputLabelProps={{
                        shrink: true,
                        classes: {
                          root: classes.labelProps
                        }
                      }}
                      InputProps={{
                        disableUnderline: true,
                        classes: { root: classes.cssOutlinedInput },
                      }}
                      value={this.state.targetData.min_speed}
                      onChange={handleChanger}
                      error={this.state.targetData?.error_min_speed}
                      helperText={
                        <span style={{ fontSize: "12px", color: 'red' }}>
                          {this.state.targetData?.error_min_speed && "Please provide a valid value"}
                        </span>
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={4}>
                  <FormControl fullWidth className={classes.formController}>
                    <TextField
                      fullWidth
                      variant="standard"
                      label="Max Speed"
                      name="max_speed"
                      type="number"
                      id="targetFormmax_speed"
                      className={classes.fieldInput1}
                      InputLabelProps={{
                        shrink: true,
                        classes: {
                          root: classes.labelProps
                        }
                      }}
                      InputProps={{
                        disableUnderline: true,
                        classes: { root: classes.cssOutlinedInput },
                      }}
                      value={this.state.targetData.max_speed}
                      onChange={handleChanger}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={4}>
                  <FormControl fullWidth className={classes.formController}>
                    <TextField
                      fullWidth
                      variant="standard"
                      label="Min Load"
                      name="min_load"
                      type="number"
                      id="targetFormmin_load"
                      className={classes.fieldInput1}
                      InputLabelProps={{
                        shrink: true,
                        classes: {
                          root: classes.labelProps
                        }
                      }}
                      InputProps={{
                        disableUnderline: true,
                        classes: { root: classes.cssOutlinedInput },
                      }}
                      value={this.state.targetData.min_load}
                      onChange={handleChanger}
                      error={this.state.targetData?.error_min_load}
                      helperText={
                        <span style={{ fontSize: "12px", color: 'red' }}>
                          {this.state.targetData?.error_min_load && "Please provide a valid value"}
                        </span>
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={4}>
                  <FormControl fullWidth className={classes.formController}>
                    <TextField
                      fullWidth
                      variant="standard"
                      label="Max Load"
                      name="max_load"
                      type="number"
                      id="targetFormmax_load"
                      className={classes.fieldInput1}
                      InputLabelProps={{
                        shrink: true,
                        classes: {
                          root: classes.labelProps
                        }
                      }}
                      InputProps={{
                        disableUnderline: true,
                        classes: { root: classes.cssOutlinedInput },
                      }}
                      value={this.state.targetData.max_load}
                      onChange={handleChanger}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={4}>
                  <FormControl fullWidth className={classes.formController}>
                    <TextField
                      fullWidth
                      variant="standard"
                      label="Production"
                      type="number"
                      name="production"
                      id="targetFormproduction"
                      className={classes.fieldInput1}
                      InputLabelProps={{
                        shrink: true,
                        classes: {
                          root: classes.labelProps
                        }
                      }}
                      InputProps={{
                        disableUnderline: true,
                        classes: { root: classes.cssOutlinedInput },
                      }}
                      value={this.state.targetData.production}
                      onChange={handleChanger}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} style={{ textAlign: "end" }} >
                  <Button id="savetargetData" className={classes.targetButton1} onClick={this.validateTargetData} variant="contained">Save</Button>
                </Grid>
              </Grid>
            </Container>
          </CardContent>
        </Card>
      </Fragment>
    );
  }
}

const styles = () => ({
  card: {
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    borderRadius: 30,
    padding: "20px"
  },
  formController: {
    padding: "5px 10px",
    marginBottom: '20px'
  },
  labelProps: {
    fontSize: "20px",
    fontWeight: 600
  },
  textFieldRoot: {
    backgroundColor: "#00000000",
    padding: "5px",
  },
  cssOutlinedInput: {
    backgroundColor: "#e6e6e6",
    borderRadius: 7,
    marginTop: "25px!important",
    padding: "7px 20px",
  },
  targetButton1: {
    backgroundColor: "#7AA2FA!important",
    borderRadius: 7,
    color: "white",
    padding: "7px 65px",
  }
});

export default withStyles(styles)(TargetSettingsForm);
