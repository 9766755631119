//@ts-nocheck
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  drawer: any;
  loading: any;
  startTime: any;
  endTime: any;
  isStartTimeValid: boolean;
  isEndTimeValid: boolean;
  startTimeErrorMessage: any;
  endTimeErrorMessage: any;
  shiftData: any;
  disableShiftBtn: any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ShiftManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createShiftApiCallId: any;
  getAllShiftApiCallId: any;
  resetShiftApiCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      drawer: false,
      loading: false,
      startTime: "",
      endTime: "",
      isStartTimeValid: true,
      isEndTimeValid: true,
      startTimeErrorMessage: "",
      endTimeErrorMessage: "",
      shiftData: [],
      disableShiftBtn: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: String, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.createShiftApiCallId) {
        this.createShiftApiCall(responseJson)
      }
      if (apiRequestCallId === this.getAllShiftApiCallId) {
        this.getAllShiftsApiCall(responseJson)
      } 
      if (apiRequestCallId === this.resetShiftApiCallId) {
        this.resetShiftApiCall(responseJson);
      }

    }
    // Customizable Area End
  }

  // Customizable Area Start
  showErrorToast = (resObj: any) => {
    if (resObj.message) {
      resObj.status !== 200 ? toast.error(resObj.message) : toast.success(resObj.message);
    }
  }

  async componentDidMount() {
    this.setState({ loading: true });
    this.getAllShifts();
  }

  handleDrawer = () => {
    this.clearFormState();
    this.setState({ drawer: !this.state.drawer });
  };

  handleStartTime = (event: any) => {
    this.setState({
      startTime: event.target.value,
      isStartTimeValid: true,
      startTimeErrorMessage: "",
    });
  };
  
  handleEndTime = (event: any) => {
    this.setState({
      endTime: event.target.value,
      isEndTimeValid: true,
      endTimeErrorMessage: "",
    });
  };

  validateTimings = () => {
    this.setState({
      disableShiftBtn: true
    })
    let apiError = false;

    if (this.state.startTime === "") {
      apiError = true;
      this.setState({
        isStartTimeValid: false,
        startTimeErrorMessage: "Field cannot be blank.",
        disableShiftBtn: false
      });
    }
    if (this.state.endTime === "") {
      apiError = true;
      this.setState({
        isEndTimeValid: false,
        endTimeErrorMessage: "Field cannot be blank.",
        disableShiftBtn: false,
      });
    }

    if (!apiError && this.state.isStartTimeValid && this.state.isEndTimeValid) {
      this.setState({ loading: true });
      let data = {
        shift: {
          start_time: this.state.startTime,
          end_time: this.state.endTime,
        },
      } as any;
      this.createShift(data);
    }
  };

  createShift = (data: any) => {
    const httpBody = data;

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.createShiftApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createShiftApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  clearFormState = () => {
    this.setState({
      startTime: "",
      endTime: "",
      isStartTimeValid: true,
      isEndTimeValid: true,
      startTimeErrorMessage: "",
      endTimeErrorMessage: "",
    });
  };

  getAllShifts = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.getAllShiftApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllShiftsApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  resetShifts = () => {
    this.setState({ loading: true });
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.resetShiftApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resetShiftApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  createShiftApiCall = (responseJson) => {
    if (responseJson.data) {
      if (Object.keys(responseJson.data.length !== 0)) {
        toast.success("Shift created successfully.");
        this.clearFormState();
        this.getAllShifts();
        this.setState({
          drawer: !this.state.drawer,
          disableShiftBtn: false
        });
      } else {
        this.setState({
          loading: false, drawer: !this.state.drawer, disableShiftBtn: false
        });
      }
    } else {
      this.setState({
        loading: false, drawer: !this.state.drawer, disableShiftBtn: false
      });
      if (responseJson.message) {
        this.showErrorToast(responseJson);
      } else {
        toast.error("Something went wrong.");
      }
    }
  }

  getAllShiftsApiCall = (responseJson) => {
    if (responseJson.data) {
      if (responseJson.data.length !== 0) {
        let tempArr = [] as any;
        let response = responseJson.data;
        let number = 0 as any;
        response.map((_data: any, idx: any) => {
          number = number + 1;
          tempArr.push({
            cardNumber: number,
            id: _data.id,
            startTime: _data.attributes.start_time,
            endTime: _data.attributes.end_time,
          });
        });
        this.setState({
          shiftData: tempArr,
          loading: false,
        });
      } else {
        this.setState({ loading: false, shiftData: responseJson.data });
      }
    } else {
      if (responseJson.message) {
        this.showErrorToast(responseJson);
      } else {
        toast.error("Something went wrong.");
      }
      this.setState({ loading: false });
    }
  }

  resetShiftApiCall = (responseJson) => {
    if (responseJson.message) {
      this.showErrorToast(responseJson);
    } else {
      toast.error("Something went wrong.");
    }
    if (responseJson.status === 200) {
      this.getAllShifts();
    } else {
      this.setState({ loading: false, shiftData: responseJson.data });
    }
  }


  // Customizable Area End
}
