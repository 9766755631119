//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { toast } from "react-toastify";
// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getformatedCurrentDate, getUserInfo, getformatedYesterDayDate } from "../../dashboard/src/utility";
import moment from "moment";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  reportsData: any;
  // startTime: string;
  // endTime: string;
  selectedDevice: any;
  subDevicesList: any;
  filterObj: any;
  isdateValid: any;
  end_dateValid: any;
  errMsgOn_endDate: any;
  isDeviceSelected: any;
  pageLoader: boolean;
  anchorEl3: any;
  errMsgOnDate: any;
  selectedReports: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AdHocReportingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start  
  getReportsDataApiId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.exportPDFData = this.exportPDFData.bind(this);
    this.exportCSVData = this.exportCSVData.bind(this);
    // this.loadMoreData = this.loadMoreData.bind(this);
    this.filterFieldChange = this.filterFieldChange.bind(this);
    this.filterReports = this.filterReports.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      reportsData: [],
      // startTime: "",
      // endTime: "",
      selectedDevice: "",
      subDevicesList: [],
      filterObj: {
        start_date: '',
        end_date: '',
        deviceId: 'all'
      },
      isdateValid: true,
      end_dateValid: true,
      isDeviceSelected: true,
      pageLoader: true,
      errMsgOnDate: "",
      errMsgOn_endDate: "",
      anchorEl3: null,
      selectedReports: {}
      // Customizable Area Start
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async getDevicesList() {
    const subDevicesList = await getUserInfo('subDevicesList');
    this.setState((prevState) => ({ ...prevState, subDevicesList: subDevicesList }))
  }

  handleAboutMenuClose = () => {
    this.setState({ anchorEl3: null, selectedReports: {} });
  };

  handleOpenAboutMenu = (event: any, data: any) => {
    this.setState({
      anchorEl3: event?.currentTarget,
      selectedReports: data
    });
  };

  filterFieldChange(e: any) {
    const filterName = e.target.name
    const filterObj = { ...this.state.filterObj };
    if (filterName == "start_date" || filterName == "end_date") {
      const today = new Date();
      const enteredDate = new Date(e.target.value);
      if (enteredDate <= today) {
        filterObj[filterName] = e.target.value;
      }
    } else {
      filterObj[filterName] = e.target.value;
    }

    this.setState({ filterObj: filterObj, isdateValid: true, end_dateValid: true, isDeviceSelected: true, errMsgOnDate: '' });
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.getReportsDataApiId) {
        const filterResponse = responseJson.filter((item: any) => item && item);
        if (responseJson) {
          if (responseJson.message) {
            this.showErrorToast(responseJson);
          } else {
            responseJson.length > 0 ? this.setState((prevState) => ({ ...prevState, reportsData: filterResponse, pageLoader: false })) : this.setState({ reportsData: [], pageLoader: false });
          }
        } else {
          toast.error("Something went wrong");
          this.setState({ reportsData: [], pageLoader: false });
        }
      }
    }

    // Customizable Area End
  }
  showErrorToast = (resObj: any) => {
    if (resObj.message) {
      resObj.status !== 200 ? toast.error(resObj.message) : toast.success(resObj.message);
    }
  }

  filterReports() {
    if (this.state.filterObj.end_date == "") {
      this.setState({
        end_dateValid: false,
        errMsgOn_endDate: "Please enter a valid end date."
      })
    }
    if (this.state.filterObj.deviceId == "") {
      this.setState({
        isDeviceSelected: false
      })
    }
    if (this.state.filterObj.start_date > this.state.filterObj.end_date || this.state.filterObj.start_date == this.state.filterObj.end_date) {
      this.setState({
        isdateValid: false,
        errMsgOnDate: "Please enter a valid start date."
      })
    }
    else {
      this.getReportsData('');
    }
  };

  resetFilters = () => {
    this.setState({ isdateValid: true, end_dateValid: true, errMsgOnDate: "", errMsgOn_endDate: "" })
    this.getReportsData('default');
  }

  getReportsData = async (type: any) => {
    let dataParams: string = "";

    if (type == "default") {
      const startDate = await getformatedYesterDayDate('time');
      const endDate = await getformatedCurrentDate('time');
      dataParams = `sub_device_id=&start_date=${startDate}&end_date=${endDate}`;
      this.setState({ reportsData: [], pageLoader: true, filterObj: { start_date: startDate, end_date: endDate, deviceId: 'all' } });
    } else {
      this.setState({ reportsData: [], pageLoader: true });
      dataParams = `sub_device_id=${this.state.filterObj.deviceId !== "all" ? this.state.filterObj.deviceId : ''}&start_date=${this.state.filterObj.start_date}&end_date=${this.state.filterObj.end_date}`;
    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.getReportsDataApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_data_import_export_csv/report?${dataParams}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  addFooters = (doc: any) => {
    const pageCount = doc.internal.getNumberOfPages();
    doc.setFont('helvetica', 'italic')
    doc.setFontSize(8)
    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i)
      doc.text(`Esskay Systems | Printed on: ${new Date().toDateString()} ${new Date().toLocaleTimeString()}`, doc.internal.pageSize.width / 2, 287, {
        align: 'center'
      })
    }
  }

  exportPDFData = async () => {
    let startDate: any = new Date(this.state.filterObj.start_date);
    let endDate: any = new Date(this.state.filterObj.end_date);
    startDate = moment(startDate).format("D/MM/YYYY HH:mm");
    endDate = moment(endDate).format("D/MM/YYYY HH:mm");
    const eskeyImage = require("../../dashboard/assets/EsskaySystemsLogo.png");
    const heading = ["Name", "Average Rate(ton/hr)", "Production"];
    const custmerName = await getUserInfo("userName");

    let deviceSidName: any;
    if (this.state.filterObj.deviceId) {
      this.state.subDevicesList.map((device: any) => {
        if (device.id == this.state.filterObj.deviceId) deviceSidName = device.sid_name
      })
    }

    const doc: any = new jsPDF();
    var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
    const tableBody = this.state.reportsData.map((item: any) => ([item.name, item.average_rate, item.production]));
    // console.log(tableBody,this.state.reportsData);    
    // doc.addImage(eskeyImage, 'JPEG', 15, 15);
    // doc.text("Production Report", 15, 35, { baseline: 'top', });
    // doc.setFontSize(12);
    // doc.text(`Date: ${startDate.toLocaleDateString()} ${startDate.toLocaleTimeString()} to ${endDate.toLocaleDateString()} ${endDate.toLocaleTimeString()}`, 15, 45, { baseline: 'top' });
    let docPageNumber: any = 1
    doc.autoTable({
      head: [heading],
      body: [...tableBody],
      margin: { top: 55, left: 7, right: 7 },
      styles: {
        minCellHeight: 11,
        halign: "left",
        valign: "center",
        fontSize: 8,
      },
      headStyles: {
        cellPadding: 2,
        lineWidth: 0,
        valign: 'middle',
        fontStyle: 'bold',
        halign: 'left',
        fontSize: 9,
      },
      didDrawPage: function (data: any) {
        // Header
        // doc.page = 1;
        data.settings.margin.top = 15
        if (docPageNumber == 1) {
          doc.addImage(eskeyImage, 'JPEG', 5, 5, 75, 30);
          doc.setFontSize(10);
          doc.text(`From Date`, 127, 10, { baseline: 'top' });
          doc.text(`:  ${startDate}`, 155, 10, { baseline: 'top' });
          doc.text(`To Date`, 127, 16, { baseline: 'top' });
          doc.text(`:  ${endDate}`, 155, 16, { baseline: 'top' });
          doc.text(`Customer Name`, 127, 22, { baseline: 'top' });
          doc.text(`:  ${custmerName}`, 155, 22, { baseline: 'top' });
          if (deviceSidName) {
            doc.text(`Device Name`, 127, 28, { baseline: 'top' });
            doc.text(`:  ${deviceSidName}`, 155, 28, { baseline: 'top' });
          }
          doc.setFontSize(22);
          doc.setFont(undefined, 'bold')
          doc.text("PRODUCTION REPORTS", pageWidth / 2, 45, { align: 'center' });
          // doc.text("Production Report", 7, 35, { baseline: 'top', });
          // doc.setFontSize(10);
          // doc.text(`Date: ${startDate.toLocaleDateString()} ${startDate.toLocaleTimeString()} to ${endDate.toLocaleDateString()} ${endDate.toLocaleTimeString()}`, 7, 45, { baseline: 'top' });
          // data.settings.margin.top = 65
        } else {

        }

        docPageNumber = 2
      },

    });
    this.addFooters(doc);
    doc.save(`reports${startDate}to${endDate}.pdf`);
    return false;
  };

  exportCSVData = () => {
    const startDate = new Date(this.state.filterObj.start_date);
    const endDate = new Date(this.state.filterObj.end_date);
    const heading = [{
      "name": "Name",
      "average_rate": "Average Rate",
      "production": "Production"
    }];
    const fileData = this.state.reportsData.map((item: any) => ({
      "name": item.name,
      "average_rate": item.average_rate,
      "production": item.production
    }));
    const forCSV = [...heading, ...fileData];
    const newCsv = forCSV.map((d: any) => Object.values(d).join(",")).join("\n");
    var data = new Blob([newCsv], { type: 'text/csv' });
    var csvURL = window.URL?.createObjectURL(data);
    var tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', `reports${startDate.toLocaleDateString()}to${endDate.toLocaleDateString()}.csv`);
    tempLink.click();
  };


  // Customizable Area Start
  // Customizable Area End
}
