//@ts-nocheck
import React, { Fragment, useEffect, useState } from "react";
import { Grid, Typography, Box } from "@material-ui/core";
import { getTimeDiffByMqtt, getTimeObjByTimeString } from "./utility";

export function TimeCounter(props: any) {
    const { classes, type, timeString } = props;
    const [counterStartTime, setCounterStartTime] = useState({ hrs: 0, min: 0 });

    const updateTime = async () => {
        const deviceObj = timeString ? await getTimeObjByTimeString(timeString) : { hrs: 0, min: 0, sec: 0 };
        setCounterStartTime({ hrs: deviceObj.hrs, min: deviceObj.min });
        // const params = window.location.pathname.split("/");
        // const deviceObj = params ? await getTimeDiffByMqtt(params[2], params[3], type) : { hrs: 0, min: 0, sec: 0 };
        // setCounterStartTime({ hrs: deviceObj.hrs, min: deviceObj.min, sec: deviceObj.sec });
    };

    useEffect(() => {
        updateTime();
        // const interval = setInterval(() => updateTime(), 10000);
        // return () => clearInterval(interval);
    }, [timeString]);

    return (
        <Fragment>
            <Grid container>
                <Grid item xs={8}>
                    <Typography className={classes.mediumText}>
                        {type == "start" ? "Run Time" : type == "down" ? "Down Time" : type == "idle" && "Idle Time"}
                    </Typography>
                    <Typography className={classes.mediumText1}>
                        (Hrs. or Min)
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography className={classes.logsDivTime}>
                        {counterStartTime.hrs > 9 ? counterStartTime.hrs : `0${counterStartTime.hrs}`} : {counterStartTime.min > 9 ? counterStartTime.min : `0${counterStartTime.min}`}
                    </Typography>
                    <Typography>
                        Hrs <span style={{ marginLeft: '5px' }}>Min</span>
                    </Typography>
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default React.memo(TimeCounter);